import { KeyValuePairs } from 'src/app/core/interfaces';


export namespace LiveStreamModels {
  export enum StreamTypes {
    Websocket,
    Webrtc,
    HLS,
    Livekit
  }

  export enum StreamAction {
    Start,
    Stop,
    AddAnalytics,
    StopAnalytics,
    Peer,
    CameraCommand,
    SendHeartbeat,
    WebrtcExtend,
    Exit,
    Reboot,
    StartLiveStream,
  }

  export interface LiveStreamOutgoingMessage {
    edgeId: string;
    cameraIds?: string[];
    type: StreamTypes;
    action: StreamAction;
    data?: any;
  }

  export interface ILiveStreamLocalOutgoingMessage extends LiveStreamOutgoingMessage {
    type: StreamTypes.Websocket;
  }

  export interface StartLiveStreamLocalIncomingMessage {
    edgeId: string;
    connectionData: KeyValuePairs<{
      port: number;
    }>;
  }

  export interface LiveKitData {
    url: string;
    token: string;
  }

  export interface HlsData {
    resolution?: StreamResolution;
    playerWidth?: number;
    playerHeight?: number;
    hlsErrorCounter?: number;
    hlsConfig?: Partial<HLS>;
  }

  export enum StreamResolution {
    SQ = 0,
    MQ = 1,
    HQ = 2,
    AUTO = 3,
  }

  export enum LiveViewErrorCode {
    OK = 0,
    Unsupported = 1,
    NotReady = 2
  }

  export interface ConnectionData {
    cameraStatus: boolean;
    resolution?: StreamResolution;
    url?: string;
    errorMsg?: string;
    errorCode?: LiveViewErrorCode;
  }


  export interface StartLiveStreamResponse {
    connectionData?: KeyValuePairs<ConnectionData>;
  }

  export enum RateControlType {
    CBR = 'cbr',
    VBR = 'vbr'
  }

  export enum RateControlPresetLevel {
    Disable = 'disable',
    UltraFast = 'ultra_fast',
    Fast = 'fast',
    Medium = 'medium',
    Slow = 'slow'
  }

  export interface HLS {
    // HQ
    hlsFragmentDuration: number,
    hlsMaxLiveFragments: number,
    hlsMaxStoredFragments: number,

    // MQ
    hlsMQScaledFragmentDuration: number,
    hlsMQMaxLiveFragments: number,
    hlsMQMaxStoredFragments: number,
    hlsMQFromMainstream: boolean,
    hlsMQScaledWidth: number,
    hlsMQScaledHeight: number,
    hlsMQScaledBitrateBps: number,
    hlsMQScaledPeakBitrateBps: number,
    hlsMQScaledIFrameInterval: number,
    hlsMQScaledIFrameIntervalFromFPS: boolean,
    hlsMQScaledRateControlType: RateControlType,
    hlsMQScaledPresetLevel: RateControlPresetLevel,

    // SQ
    hlsSQScaledFragmentDuration: number,
    hlsSQMaxLiveFragments: number,
    hlsSQMaxStoredFragments: number,
    hlsSQScaledWidth: number,
    hlsSQScaledHeight: number,
    hlsSQScaledBitrateBps: number,
    hlsSQScaledPeakBitrateBps: number,
    hlsSQScaledIFrameInterval: number,
    hlsSQScaledIFrameIntervalFromFPS: boolean,
    hlsSQScaledRateControlType: RateControlType,
    hlsSQScaledPresetLevel: RateControlPresetLevel,
  }
}
