export module PermissionModel {


  export enum Entity {
    Location = 'location',
    Core = 'core',
    Camera = 'camera',
    OrganizationSettings = 'organizationSettings',
    UserInvites = 'userInvites',
    Archive = 'archive',
    Wall = 'wall',
    OrganizationDatabase = 'organizationDatabase',
    Event = 'event',
    Alert = 'alert',
    Search = 'search',
    Dashboards = 'dashboards'
  }

  export const entityNameMap: { [Property in Entity]: string } = {
    [Entity.Location]: 'Location',
    [Entity.Core]: 'Core',
    [Entity.Camera]: 'Camera',
    [Entity.OrganizationSettings]: 'Organization',
    [Entity.UserInvites]: 'Invites',
    [Entity.Archive]: 'Archive',
    [Entity.Wall]: 'Wall',
    [Entity.OrganizationDatabase]: 'Organization database',
    [Entity.Event]: 'Event',
    [Entity.Alert]: 'Alert',
    [Entity.Search]: 'Search',
    [Entity.Dashboards]: 'Dashboards',
  };


  /**
   * MUST BE SAME AS BACKEND
   */

  export enum Permissions {
    LocationRead = 'LocationRead',
    LocationCreate = 'LocationCreate',
    LocationEdit = 'LocationEdit',
    LocationDelete = 'LocationDelete',

    CoreRead = 'CoreRead',
    CoreCreate = 'CoreCreate',
    CoreEdit = 'CoreEdit',
    CoreRestart = 'CoreRestart',
    CoreDelete = 'CoreDelete',

    CameraCreate = 'CameraCreate',
    CameraRead = 'CameraRead',
    CameraEdit = 'CameraEdit',
    CameraDelete = 'CameraDelete',
    CameraLiveView = 'CameraLiveView',
    CameraPlayback = 'CameraPlayback',
    CameraLiveViewShare = 'CameraLiveViewShare',
    CameraPlaybackShare = 'CameraPlayBackShare',

    //organization settings
    OrganizationSettingsInviteUsers = 'OrganizationsettingsInviteUsers',
    OrganizationSettingsEditOrganization = 'OrganizationsettingsEditOrganization',
    OrganizationSettingsReadUserList = 'OrganizationsettingsReadUserList',
    OrganizationSettingsManageUserPermissions = 'OrganizationsettingsManageUserPermissions',
    OrganizationSettingsRemoveUser = 'OrganizationsettingsRemoveUser',
    OrganizationSettingsDeleteOrganization = 'OrganizationsettingsDeleteOrganization',

    OrganizationSettingsGroups = 'OrganizationsettingsGroups',
    OrganizationSettingsSharing = 'OrganizationsettingsSharing',
    OrganizationSettingsUserLogs = 'OrganizationsettingsUserLogs',
    OrganizationSettingsAuditLogs = 'OrganizationsettingsAuditLogs',
    OrganizationSettingsAlertLogs = 'OrganizationsettingsAlertLogs',
    OrganizationSettingsAuditReports = 'OrganizationsettingsAuditReports',
    OrganizationSettingsApiKeys = 'OrganizationsettingsApiKeys',
    OrganizationSettingsPermissions = 'OrganizationsettingsPermissions',
    OrganizationSettingsLoginFirewall = 'OrganizationsettingsLoginFirewall',
    OrganizationSettingsIntegrations = 'OrganizationsettingsIntegrations',

    //organization database
    OrganizationDatabase = 'Organizationdatabase',
    OrganizationDatabasePeopleDirectory = 'OrganizationdatabasePeopleDirectory',
    OrganizationDatabaseDoorsDirectory = 'OrganizationdatabaseDoorsDirectory',
    OrganizationDatabaseVehiclesDirectory = 'OrganizationdatabaseVehiclesDirectory',
    OrganizationDatabaseEventTags = 'OrganizationdatabaseEventTags',

    //archive
    ArchiveRead = 'ArchiveRead',
    ArchiveCreate = 'ArchiveCreate',
    ArchiveShare = 'ArchiveShare',
    ArchiveShareRevoke = 'ArchiveShareRevoke',
    ArchiveDownload = 'ArchiveDownload',
    ArchiveDelete = 'ArchiveDelete',

    //
    WallRead = 'WallRead',
    WallEdit = 'WallEdit',
    WallDelete = 'WallDelete',
    WallCreate = 'WallCreate',
    WallQuickLiveView = 'WallQuickLiveView',

    //
    EventRead = 'EventRead',
    EventCreate = 'EventCreate',
    EventEdit = 'EventEdit',
    EventDelete = 'EventDelete',

    //
    AlertMonitoring = 'AlertCrete',
    AlertDelete = 'AlertDelete',
    AlertAck = 'AlertAck',
    AlertShare = 'AlertShare',

    SearchView = 'SearchView',
    DashboardView = 'DashboardView',

  }

  export const permissionDisabledEntity = {
    [Permissions.LocationCreate]: [Entity.Location],
    [Permissions.CoreCreate]: [Entity.Core],
    [Permissions.CameraCreate]: [Entity.Camera],
  };

  export const entityPermissionsDisabled = {
    [Entity.Location]: [Permissions.LocationCreate],
    [Entity.Core]: [Permissions.CoreCreate],
    [Entity.Camera]: [Permissions.CameraCreate],
  };

  export interface PermissionListItemPermission {
    name: string,
    description: string,
    value: Permissions,
    disabled: Entity[],
  }

  export interface PermissionListItem {
    entity: Entity,
    permissions: PermissionListItemPermission []
  }


  export const locationPermissions: PermissionListItemPermission[] = [
    {
      name: 'Read',
      description: 'Permission to read locations.',
      value: Permissions.LocationRead,
      disabled: [],
    },
    {
      name: 'Create',
      description: 'Permission to create location.',
      value: Permissions.LocationCreate,
      disabled: permissionDisabledEntity[Permissions.LocationCreate],
    },
    {
      name: 'Edit',
      description: 'Permission to edit properties for locations.',
      value: Permissions.LocationEdit,
      disabled: [],
    },
    {
      name: 'Delete',
      description: 'Permission to delete locations.',
      value: Permissions.LocationDelete,
      disabled: [],
    },

  ];

  export const corePermissions: PermissionListItemPermission[] = [
    {
      name: 'Read',
      description: 'Permission to read core.',
      value: Permissions.CoreRead,
      disabled: [],
    },
    {
      name: 'Create',
      description: 'Permission to create core.',
      value: Permissions.CoreCreate,
      disabled: permissionDisabledEntity[Permissions.CoreCreate],
    },
    {
      name: 'Restart',
      description: 'Permission to restart core.',
      value: Permissions.CoreRestart,
      disabled: [],
    },
    {
      name: 'Edit',
      description: 'Permission to edit properties for cores.',
      value: Permissions.CoreEdit,
      disabled: [],
    },
    {
      name: 'Delete',
      description: 'Permission to delete cores.',
      value: Permissions.CoreDelete,
      disabled: [],
    },
  ];

  export const cameraPermissions: PermissionListItemPermission[] = [
    {
      name: 'Read',
      description: 'Permission to read camera.',
      value: Permissions.CameraRead,
      disabled: [],
    },
    {
      name: 'Create',
      description: 'Permission to add camera.',
      value: Permissions.CameraCreate,
      disabled: permissionDisabledEntity[Permissions.CameraCreate],
    },
    {
      name: 'Live View',
      description: 'Permission to stream camera.',
      value: Permissions.CameraLiveView,
      disabled: [],
    },
    {
      name: 'Share live view',
      description: 'Permission to share camera live view.',
      value: Permissions.CameraLiveViewShare,
      disabled: [],
    },

    {
      name: 'Playback',
      description: 'Camera playback access',
      value: Permissions.CameraPlayback,
      disabled: [],
    },

    {
      name: 'Share playback',
      description: 'Camera playback share',
      value: Permissions.CameraPlaybackShare,
      disabled: [],
    },
    {
      name: 'Edit',
      description: 'Permission to edit properties for camera.',
      value: Permissions.CameraEdit,
      disabled: [],
    },
    {
      name: 'Delete',
      description: 'Permission to delete camera.',
      value: Permissions.CameraDelete,
      disabled: [],
    },
  ];

  export const organizationPermissions: PermissionListItemPermission[] = [
    {
      name: 'Edit',
      description: 'Permission to edit organization.',
      value: Permissions.OrganizationSettingsEditOrganization,
      disabled: [],
    },
    {
      name: 'Users access',
      description: 'Permission to see organization users',
      value: Permissions.OrganizationSettingsReadUserList,
      disabled: [],
    },
    {
      name: 'Groups access',
      description: 'Permission to see organization groups',
      value: Permissions.OrganizationSettingsGroups,
      disabled: [],
    },
    {
      name: 'Sharing access',
      description: 'Permission to see organization sharing',
      value: Permissions.OrganizationSettingsSharing,
      disabled: [],
    },
    {
      name: 'User logs',
      description: 'Permission to see user logs',
      value: Permissions.OrganizationSettingsUserLogs,
      disabled: [],
    },
    {
      name: 'Audit logs',
      description: 'Permission to see user audit logs',
      value: Permissions.OrganizationSettingsAuditLogs,
      disabled: [],
    },
    {
      name: 'Alert logs',
      description: 'Permission to see user alert logs',
      value: Permissions.OrganizationSettingsAlertLogs,
      disabled: [],
    },
    {
      name: 'Audit reports',
      description: 'Permission to see audit reports',
      value: Permissions.OrganizationSettingsAuditReports,
      disabled: [],
    },
    {
      name: 'API keys',
      description: 'Permission to see api keys',
      value: Permissions.OrganizationSettingsApiKeys,
      disabled: [],
    },
    {
      name: 'Permissions',
      description: 'Permission to see permissions',
      value: Permissions.OrganizationSettingsPermissions,
      disabled: [],
    },
    {
      name: 'Login firewall',
      description: 'Permission to setup login firewall',
      value: Permissions.OrganizationSettingsLoginFirewall,
      disabled: [],
    },
    {
      name: 'Delete',
      description: 'Permission to delete organization.',
      value: Permissions.OrganizationSettingsDeleteOrganization,
      disabled: [],
    },
  ];

  export const userInvitesPermissions: PermissionListItemPermission[] = [
    {
      name: 'Create',
      description: 'Permission to invite users to organizations.',
      value: Permissions.OrganizationSettingsInviteUsers,
      disabled: [],
    },
    {
      name: 'Edit',
      description: 'Permission to manage user permissions in organization',
      value: Permissions.OrganizationSettingsManageUserPermissions,
      disabled: [],
    },
    {
      name: 'Delete',
      description: 'Permission to remove users from organizations.',
      value: Permissions.OrganizationSettingsRemoveUser,
      disabled: [],
    },

  ];

  export const archivePermissions: PermissionListItemPermission[] = [
    {
      name: 'Read',
      description: 'Permission to view archive.',
      value: Permissions.ArchiveRead,
      disabled: [],
    },
    {
      name: 'Create',
      description: 'Permission to create archive.',
      value: Permissions.ArchiveCreate,
      disabled: [],
    },
    {
      name: 'Share',
      description: 'Permission to share archive.',
      value: Permissions.ArchiveShare,
      disabled: [],
    },
    {
      name: 'Revoke shared archive',
      description: 'Permission to revoke shared archives.',
      value: Permissions.ArchiveShareRevoke,
      disabled: [],
    },
    {
      name: 'Download',
      description: 'Permission to download archive',
      value: Permissions.ArchiveDownload,
      disabled: [],
    },
    {
      name: 'Delete',
      description: 'Permission to delete archive.',
      value: Permissions.ArchiveDelete,
      disabled: [],
    },
  ];

  export const wallPermissions: PermissionListItemPermission[] = [
    {
      name: 'Read',
      description: 'Permission to access to walls',
      value: Permissions.WallRead,
      disabled: [],
    },
    {
      name: 'Quick live view',
      description: 'Permission to access to quick live view',
      value: Permissions.WallQuickLiveView,
      disabled: [],
    },
    {
      name: 'Create',
      description: 'Permission to create walls',
      value: Permissions.WallCreate,
      disabled: [],
    },
    {
      name: 'Edit',
      description: 'Permission to edit walls',
      value: Permissions.WallEdit,
      disabled: [],
    },
    {
      name: 'Delete',
      description: 'Permission to delete any wall in entire organization',
      value: Permissions.WallDelete,
      disabled: [],
    },

  ];

  export const organizationDatabasePermissions: PermissionListItemPermission[] = [
    {
      name: 'Read',
      description: 'Access to organization database',
      value: Permissions.OrganizationDatabase,
      disabled: [],
    },
    {
      name: 'People directory',
      description: 'Access to organization database people directory',
      value: Permissions.OrganizationDatabasePeopleDirectory,
      disabled: [],
    },
    {
      name: 'Doors directory',
      description: 'Access to organization database doors directory',
      value: Permissions.OrganizationDatabaseDoorsDirectory,
      disabled: [],
    },
    {
      name: 'Vehicle directory',
      description: 'Access to organization database vehicle directory',
      value: Permissions.OrganizationDatabaseVehiclesDirectory,
      disabled: [],
    },
    {
      name: 'Event tags',
      description: 'Access to organization database event tags',
      value: Permissions.OrganizationDatabaseEventTags,
      disabled: [],
    },
  ];

  export const alertPermissions: PermissionListItemPermission[] = [
    {
      name: 'Monitoring',
      description: 'Access to alert monitoring',
      value: Permissions.AlertMonitoring,
      disabled: [],
    },
    {
      name: 'Delete',
      description: 'Permission to delete alert',
      value: Permissions.AlertDelete,
      disabled: [],
    },
    {
      name: 'Acknowledge alert',
      description: 'Permission to acknowledge alert',
      value: Permissions.AlertAck,
      disabled: [],
    },
    {
      name: 'Share',
      description: 'Permission to share alert',
      value: Permissions.AlertShare,
      disabled: [],
    },
  ];

  export const eventPermissions: PermissionListItemPermission[] = [
    {
      name: 'Read',
      description: 'Access to event list',
      value: Permissions.EventRead,
      disabled: [],
    },
    {
      name: 'Create',
      description: 'Permission to create event',
      value: Permissions.EventCreate,
      disabled: [],
    },
    {
      name: 'Edit',
      description: 'Permission to edit event',
      value: Permissions.EventEdit,
      disabled: [],
    },
    {
      name: 'Delete',
      description: 'Permission to delete event',
      value: Permissions.EventDelete,
      disabled: [],
    },
  ];

  export const searchPermissions: PermissionListItemPermission[] = [
    {
      name: 'View',
      description: 'View search',
      value: Permissions.SearchView,
      disabled: [],
    },
  ];

  export const dashboardPermissions: PermissionListItemPermission[] = [
    {
      name: 'View',
      description: 'View dashboard',
      value: Permissions.DashboardView,
      disabled: [],
    },
  ];

  /**
   * Below defined list maps
   * it show hierarchy of entities
   */
  export const organizationPermissionListMap: { [Property in Entity]: PermissionListItemPermission [] } = {
    [Entity.Location]: locationPermissions,
    [Entity.Core]: corePermissions,
    [Entity.Camera]: cameraPermissions,
    [Entity.OrganizationSettings]: organizationPermissions,
    [Entity.UserInvites]: userInvitesPermissions,
    [Entity.Archive]: archivePermissions,
    [Entity.Wall]: wallPermissions,
    [Entity.OrganizationDatabase]: organizationDatabasePermissions,
    [Entity.Event]: eventPermissions,
    [Entity.Alert]: alertPermissions,
    [Entity.Search]: searchPermissions,
    [Entity.Dashboards]: dashboardPermissions,
  };


  export const locationPermissionListMap: { [key: string]: PermissionListItemPermission[] } = {
    [Entity.Location]: locationPermissions,
    [Entity.Core]: corePermissions,
    [Entity.Camera]: cameraPermissions,
  };

  export const corePermissionListMap = {
    [Entity.Core]: corePermissions,
    [Entity.Camera]: cameraPermissions,
  };

  export const cameraPermissionListMap = {
    [Entity.Camera]: cameraPermissions,
  };

  export const organizationSettingsPermissionListMap = {
    [Entity.OrganizationSettings]: organizationPermissions,
  };

  export const userInvitePermissionListMap = {
    [Entity.UserInvites]: userInvitesPermissions,
  };

  export const archivePermissionListMap = {
    [Entity.Archive]: archivePermissions,
  };

  export const wallPermissionListMap = {
    [Entity.Wall]: wallPermissions,
  };

  export const organizationDatabasePermissionListMap = {
    [Entity.OrganizationDatabase]: organizationDatabasePermissions,
  };

  export const alertPermissionListMap = {
    [Entity.Alert]: alertPermissions,
  };

  export const eventPermissionListMap = {
    [Entity.Event]: eventPermissions,
  };

  export const searchPermissionListMap = {
    [Entity.Search]: searchPermissions,
  };

  export const dashboardPermissionListMap = {
    [Entity.Dashboards]: dashboardPermissions,
  };

  /**
   * Single point of trust
   */
  export const entityPermissionMap: { [Property in Entity]: { [key: string]: PermissionListItemPermission[] } } = {
    [Entity.Location]: locationPermissionListMap,
    [Entity.Core]: corePermissionListMap,
    [Entity.Camera]: cameraPermissionListMap,
    [Entity.OrganizationSettings]: organizationSettingsPermissionListMap,
    [Entity.UserInvites]: userInvitePermissionListMap,
    [Entity.Archive]: archivePermissionListMap,
    [Entity.Wall]: wallPermissionListMap,
    [Entity.OrganizationDatabase]: organizationDatabasePermissionListMap,
    [Entity.Event]: eventPermissionListMap,
    [Entity.Alert]: alertPermissionListMap,
    [Entity.Search]: searchPermissionListMap,
    [Entity.Dashboards]: dashboardPermissionListMap,
  };


  export interface SelectedEntity {
    id: string;
    type: Entity,
    name: string
  }


}


