import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AlertEntry } from '../../../../development/alerts.service';
import { AlertType, AnalyticClasses, DetectionType } from '@enums/alert-events.enum';
import { analyticClassName, DetectionTypeLookup } from '@consts/alert-events.const';
import { Location } from '../../../../locations/location.model';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import * as AlertMonitoringActions from '@states/alert-monitoring/alert-monitoring.actions';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import * as ArchiveAction from '@states/archive/archive.actions';
import { LocationSelectors } from '@states/location/location.selector-types';
import { BehaviorSubject, lastValueFrom, Observable, shareReplay, take } from 'rxjs';
import { PlaybackPlayerDialogData } from '../../../../shared/playback-player-dialog/playback-player-dialog.component';
import { CameraThumbnailsData, ThumbOptions } from '../../../../cameras/camera-thumbnails/camera-thumbnails.model';
import { MultiPlaybackData } from '@models/multi-playback.model';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { ThumbnailDialogComponent, ThumbnailDialogData, ThumbnailDialogType } from '../../../../shared/thumbnail/thumbnail-dialog/thumbnail-dialog.component';
import { CamerasThumbnailsService } from '../../../../cameras/camera-thumbnails/camera-thumnails.service';
import { environment } from '../../../../../environments/environment';
import { KeyValuePairs } from '../../../../core/interfaces';
import { EdgeStatusService } from '../../../../edge/edge-status.service';
import { CameraLiveViewDialogComponent, CameraLiveViewDialogData } from '../../../../shared/camera-live-view-dialog/camera-live-view-dialog.component';
import { AnalyticStatus, EdgeCamera } from '../../../../cameras/camera.model';
import * as moment from 'moment/moment';
import * as AlertMonitoringSelectors from '@states/alert-monitoring/alert-monitoring.selectors';
import { CameraStatus } from '../../../../development/heartbeat.service';
import { ViewStructureModalComponent } from '../../../../shared/modals/view-structure-modal/view-structure-modal.component';
import { SharedActions } from '@states/shared/shared.action-types';
import { ConfirmDialogType } from '../../../../shared/confirm-dialog/confirm-dialog.model';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PreloaderColor } from '@enums/shared.enum';
import { AlertV2TypeGroup } from '@consts/alerts-v2.const';
import { StepOptionSelectorGroupEntry } from '../../../alerts-v2/components/step-option-selector/step-option-selector.component';
import { GrantedAccessModel } from '@models/granted-access-model';
import { GrantedAccessActions } from '@states/granted-access/granted-access.action-types';
import { GrantedAccessType } from '@enums/granted-access.enum';
import { TrackObjectState } from '@states/track-object/track-object.reducer';
import * as TrackObjectActions from '@states/track-object/track-object.actions';
import { Router } from '@angular/router';
import { UiSnapshotV2Component } from '../../../../shared/ui-kit/ui-snapshot-v2/ui-snapshot-v2.component';
import { PulsationModels } from '@models/pulsation.model';
import { UiDatetimeRangePickerModel } from '../../../../shared/ui-kit/ui-calendar/ui-datetime-range-picker.model';
import { AlertCategory, StatusType } from '@models/alerts-v2.model';
import { ShareAccessComponent } from '../../../../shared/modals/share-access/share-access.component';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;
import { ThumbnailTemplate } from 'src/app/shared/thumbnail/thumbnail.component';
import { FadeOutAnimation } from '../../../../framework/animations';
import { MediaCacheService } from '../../../../shared/media-cache/media-cache.service';
import { PermissionSelectors } from '@states/permissions/permissions.selector-types';
import { PermissionModel } from '@models/permission.model';
import { bottom } from '@popperjs/core';

@UntilDestroy()
@Component({
  selector: 'app-alert-preview',
  templateUrl: './alert-preview.component.html',
  styleUrls: ['./alert-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FadeOutAnimation],
})
export class AlertPreviewComponent implements OnInit, OnDestroy, OnChanges {

  public ThumbnailTemplate = ThumbnailTemplate;
  public isDeveloper$: Observable<boolean> = this.store$.select(OrganizationSelectors.isDeveloper);

  @Input() edgePulsationStatus: PulsationModels.Store.EdgePulsation;
  @Input() cameraPulsationStatus: PulsationModels.Store.CameraPulsation;

  public AnalyticClasses = AnalyticClasses;
  public AlertCategory = AlertCategory;
  public StatusType = StatusType;
  public selectSelectedAlert$: Observable<AlertEntry>;

  public snapshotLoaded = true;

  @ViewChild('snapshot') snapshot: UiSnapshotV2Component;

  @Input() alert: AlertEntry;
  @Input() frequencyFilter: {
    value: number;
    unit: CustomUnit;
  }; //time older than alert should be removed from list

  @Input() locationLookup: {
    [key: string]: Location.LocationItem;
  };

  @Input() cameraLookup: {
    [key: string]: EdgeCamera.CameraItem;
  };
  public detectionTypes = DetectionType;
  public alertTypes = AlertType;
  public statusTypes = StatusType;
  public analyticClassName = analyticClassName;
  public edgeHeartBeatStatuses = PulsationModels.ComponentStatus;
  public archivedCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public cameraStatuses = CameraStatus;
  public analyticsStatuses = AnalyticStatus;
  public activeImageIndex: number = 0;
  public alertsCaterogy = AlertCategory;

  private events: KeyValuePairs<number[]> = {};
  private intervalCheckArchived;
  public preloaderColor = PreloaderColor;

  public snapshotV2 = false;

  public thumb: ThumbnailDialogData;
  public thumbData: CameraThumbnailsData;

  public previewEnabled = false;
  public removeSnapshot = false;


  constructor(
    private cd: ChangeDetectorRef,
    private dialog: MatDialog, private store$: Store<AppState>, private cameraThumbnailsService: CamerasThumbnailsService,
    private mediaCacheService: MediaCacheService,
    private edgeStatusService: EdgeStatusService, private router: Router) {
  }

  public edgeName(edgeId: string) {
    return this.store$.select(EdgeSelectors.selectEdgeNameById(edgeId));
  }

  public cameraName(cameraId: string) {
    return this.store$.select(CameraSelectors.selectCameraNameById(cameraId));
  }

  public get flowType() {
    const category = this.alert?.event?.selectedFlow?.category;
    const flowType = this.alert?.event?.selectedFlow?.flowType;
    if ((!category && category !== 0) || (!flowType && flowType !== 0)) {
      return null;
    }
    const options: StepOptionSelectorGroupEntry[] = AlertV2TypeGroup[category]?.options;
    return options?.filter((option) => option?.value?.type === flowType)[0]?.name;
  }

  public get flowTypeIcon() {
    const category = this.alert?.event?.selectedFlow?.category;
    const flowType = this.alert?.event?.selectedFlow?.flowType;
    if ((!category && category !== 0) || (!flowType && flowType !== 0)) {
      return null;
    }
    const options: StepOptionSelectorGroupEntry[] = AlertV2TypeGroup[category]?.options;
    return options?.filter((option) => option?.value?.type === flowType)[0]?.icon;
  }

  ngOnInit(): void {
    this.selectSelectedAlert$ = this.store$.select(AlertMonitoringSelectors.selectSelectedAlert(this.alert._id));
    this.selectSelectedAlert$.pipe(take(1))
      .subscribe((alert) => {
        if (!alert?.timestamp) {
          return;
        }
        if ((new Date().getTime()) - alert.timestamp < 20000) {
          this.snapshotV2 = true;
        }
        const delta = Date.now() - alert.timestamp;
        if (delta < 2 * 60 * 1000) {
          setTimeout(() => {
            this.mediaCacheService.getThumbnailBits([alert.selectedCamera], alert.timestamp - 32000, alert.timestamp + 32000);
          }, delta + 10000);
        }

      });

    if (this.frequencyFilter) {
      this.startFrequencyCounter();
    }
  }

  private startFrequencyCounter() {
    this.intervalCheckArchived = setInterval(() => {
      /**
       * Auto archive counter if exist
       */
      if (this.alert?.archivedAt >= moment()
        .unix() * 1000) {
        const archivedCounter = (this.alert?.archivedAt - moment()
          .unix() * 1000) / 1000;
        this.archivedCounter$.next(archivedCounter);
        const nowMS = moment()
          .unix() * 1000;
        if (this.alert?.archivedAt <= nowMS) {
          clearInterval(this.intervalCheckArchived);
        }
      }
    }, 1000);
  }

  public archiveAlert(isArchive: boolean) {
    this.store$.dispatch(AlertMonitoringActions.archiveAlert({ id: this.alert._id, isArchive: isArchive }));
  }

  public initThumbnail() {
    const alert = this.alert;
    if (this.previewEnabled || !alert) {
      return;
    }

    this.store$.pipe(select(CameraSelectors.selectCameraById(alert.cameraId)))
      .subscribe(res => {
        this.store$.dispatch(ArchiveAction.changeArchiveProperty({ property: 'selectedCamera', value: res }));
      });
    this.store$
      .select(LocationSelectors.selectLocationTimezone(alert?.selectedCamera?.locationId))
      .pipe(take(1))
      .subscribe(async timezone => {
        const data: PlaybackPlayerDialogData = {
          cameraId: alert.cameraId,
          cameraName: alert.eventName,
          duration: 0,
          time: alert.timestamp,
          timezone: timezone ?? 'Israel',
        };

        const startTime = this.normalizeTimestamp(alert.timestamp - 32000);
        const endTime = this.normalizeTimestamp(Math.min(alert.timestamp + 32000, new Date().getTime()));
        const options: ThumbOptions = {
          clipInSeconds: 7200,
          offsetResInDurations: 1,
          startTime,
          endTime,
          duration: 2000,
        };
        this.thumbData = {
          edgeId: alert.edgeId,
          cameraId: alert.cameraId,
          timezone: timezone,
          offsetResInDurations: 1,
        };

        this.thumb = {
          options,
          events: null,
          edgeId: alert.edgeId,
          cameraId: alert.cameraId,
          objects: alert?.thumbnails?.map(thumb => {
            return {
              url: thumb,
              idIndex: alert.idIndex,
              idBase: alert.idBase,
              descriptor: false,
            };
          }),
          type: ThumbnailDialogType.ALERT,
          rtl: false,
          defaultThumb: alert.mainThumbnail,
          showObjects: false,
          seconds: true,
          allowTrack: alert.idBase && alert.idIndex && alert.type === AnalyticClasses.person,
          alertName: alert.eventName,
          isShareVisible: true,
          shareName: this.alert.eventName,
          alertId: this.alert._id,
          name: alert.eventName,
          alertTs: alert?.timestamp,
        };
        this.previewEnabled = true;
        setTimeout(() => {
          this.removeSnapshot = true;
          this.cd.detectChanges();
        }, 1000);
      });

  }


  public playback(): void {
    const alert = this.alert;
    this.store$.pipe(select(CameraSelectors.selectCameraById(alert.cameraId)))
      .subscribe(res => {
        this.store$.dispatch(ArchiveAction.changeArchiveProperty({ property: 'selectedCamera', value: res }));
      });
    this.store$
      .select(LocationSelectors.selectLocationTimezone(alert.selectedCamera.locationId))
      .pipe(take(1))
      .subscribe(async timezone => {
        const data: PlaybackPlayerDialogData = {
          cameraId: alert.cameraId,
          cameraName: alert.eventName,
          duration: 0,
          time: alert.timestamp,
          timezone: timezone ?? 'Israel',
        };

        const startTime = this.normalizeTimestamp(alert.timestamp - 32000);
        const endTime = this.normalizeTimestamp(Math.min(alert.timestamp + 32000, new Date().getTime()));
        const options: ThumbOptions = {
          clipInSeconds: 7200,
          offsetResInDurations: 60,
          startTime,
          endTime,
          duration: 2000,
        };
        const thumbsData: CameraThumbnailsData = {
          edgeId: alert.edgeId,
          cameraId: alert.cameraId,
          timezone: timezone,
          offsetResInDurations: 60,
        };

        const multiPlaybackData: MultiPlaybackData = {
          base: startTime,
          duration: 2000,
          endTime,
          offsetResInDurations: 1,
          startTime,
          timezone: moment.tz.guess(),
        };
        this.store$.dispatch(MultiPlaybackActions.setData({ data: multiPlaybackData }));

        this.cameraThumbnailsService.setThumbnailsData(thumbsData);

        const dialogData: ThumbnailDialogData = {
          options,
          events: null,
          edgeId: alert.edgeId,
          cameraId: alert.cameraId,
          objects: alert?.thumbnails?.map(thumb => {
            return {
              url: thumb,
              idIndex: alert.idIndex,
              idBase: alert.idBase,
              descriptor: false,
            };
          }),
          type: ThumbnailDialogType.ALERT,
          rtl: false,
          defaultThumb: alert.mainThumbnail,
          showObjects: false,
          seconds: true,
          allowTrack: alert.idBase && alert.idIndex && alert.type === AnalyticClasses.person,
          alertName: alert.eventName,
          isShareVisible: true,
          shareName: this.alert.eventName,
          alertId: this.alert._id,
          alertInstanceId: this.alert.alertInstanceId,
          name: alert.eventName,
          alertTs: alert?.timestamp,
          initialTs: alert?.timestamp,
        };
        this.dialog.open(ThumbnailDialogComponent, {
          panelClass: 'thumbnail-dialog-wrapper',
          width: '110vh',
          maxHeight: '95vh',
          data: dialogData,
        });
      });
  }

  normalizeTimestamp(timestamp: number) {
    return this.cameraThumbnailsService.normalizeTimestamp(timestamp);
  }

  getAlertEvents(alert: AlertEntry) {
    return this.spreadEvents([286331153, 554766609]);
  }


  spreadEvents(eventsRaw: number[]): number[] {
    const events: number[] = [];
    for(let index = 0; index < eventsRaw.length; index++) {
      let val = eventsRaw[index];
      if (val) {
      }
      events.push(val & 0x0000000f);
      events.push((val & 0x000000f0) >> (4 * 1));
      events.push((val & 0x00000f00) >> (4 * 2));
      events.push((val & 0x0000f000) >> (4 * 3));
      events.push((val & 0x000f0000) >> (4 * 4));
      events.push((val & 0x00f00000) >> (4 * 5));
      events.push((val & 0x0f000000) >> (4 * 6));
      events.push((val & 0xf0000000) >> (4 * 7));
    }
    return events;
  }


  getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date);
  }

  getThumbnailsRaw(data: CameraThumbnailsData, startTime: number, endTime: number) {
    data.offsetResInDurations = 45;

    const baseUrl = `${environment.thumbnailsUrl}/thumbnails/${data.edgeId}/${data.cameraId}`;
    const start = this.getBaseInLocale(new Date(startTime));
    const end = this.getBaseInLocale(new Date(endTime));
    return this.cameraThumbnailsService.getThumbnailsByDateFromDb(data.edgeId, data.cameraId, start, end);
  }

  getEdgeStatus(edgeId: string): Observable<PulsationModels.ComponentStatus> {
    return this.edgeStatusService.getEdgePulsationStatus(edgeId);
  }


  public liveStream(): void {
    const alert = this.alert;
    const name = this.cameraLookup[alert.cameraId]?.edgeOnly.name;
    const data: CameraLiveViewDialogData = {
      camera: {
        edgeId: alert.edgeId,
        locationId: alert.selectedCamera.locationId,
        cameraId: alert.cameraId,
        name,
      },
    };
    this.dialog.open(CameraLiveViewDialogComponent, {
      width: '60vw',
      maxWidth: '1100px',
      data,
    });
  }

  public cancelArchive() {
    this.archiveAlert(false);
    if (this.intervalCheckArchived) {
      clearInterval(this.intervalCheckArchived);
    }
  }

  public slideTo(index: number) {
    this.activeImageIndex = index;
    // if (this.snapshotV2) {
    //   this.snapshot.reload();
    // }
  }

  public ngOnDestroy() {
    if (this.intervalCheckArchived) {
      clearInterval(this.intervalCheckArchived);
    }
  }

  public getSnapshotUrl(objectSnapshot = false): string {
    if (!this.activeImageIndex && !objectSnapshot) {
      return `https://d3tvpyeoj1ar6p.cloudfront.net/thumbnails/${this.alert.edgeId}/${this.alert.cameraId}/${this.alert.mainThumbnail}`;
    } else {
      return `https://d32bjbwejsjjzu.cloudfront.net/alerts/${this.alert.edgeId}/${this.alert.cameraId}/${this.alert.thumbnails[objectSnapshot ? 0 : this.activeImageIndex - 1]}`;
    }
  }

  public viewStructure() {
    this.dialog
      .open(ViewStructureModalComponent, {
        panelClass: 'thumbnail-dialog-wrapper',
        width: '502px',
        height: '802px',
        data: this.alert,
      })
      .afterClosed();
  }

  public delete() {
    this.store$.dispatch(SharedActions.showConfirmModal({
      options: {
        type: ConfirmDialogType.CONFIRM,
        msg: `Are you sure you want to delete ${this.alert.eventName}?`,
        title: `Delete`,
        confirm: 'Yes',
        disableClose: true,
        params: {
          id: this.alert._id,
          isAlert: true,
        },
      },
    }));
  }

  public dateLessThanNow(alert: AlertEntry): boolean {
    if (!alert?.archivedAt) {
      return false;
    }
    const isLess = alert.archivedAt < Date.now();
    return isLess;
  }

  public sharePlayback() {

    const alert = this.alert;

    const alertMoment = moment(alert.timestamp);

    const alertTime = alertMoment.unix() * 1000;
    const startTime = alertMoment.clone()
      .subtract(3, 'minute')
      .unix() * 1000;
    const endTime = alertMoment.clone()
      .add(3, 'minute')
      .unix() * 1000;

    const entityParams: GrantedAccessModel.PlaybackParams = {
      locationId: alert.selectedCamera.locationId,
      edgeId: alert.selectedCamera.edgeId,
      cameraId: alert.selectedCamera.cameraId,
      alertTime,
      startTime,
      endTime,
      alertCameraTimezone: alert.selectedCamera.timezone,
    };

    // Todo: all those parameters should come from sharing dialog
    this.store$.dispatch(
      GrantedAccessActions.createGrantedAccess({
        grantedAccess: {
          type: GrantedAccessType.PLAYBACK,
          entityId: alert._id,
          allowDownload: false,
          accessPeriod: 24,
          isPassword: true,
          password: '12345',
          confirmPassword: '12345',
          message: `Alert video of type ${alert?.event?.name} with message: ${alert.alertMessage} has beed shared with you at: ${new Date()}`,
          notifyEnabled: false,
          emails: [],
          phones: [],
          name: alert.eventName,
          entityParams,
        },
      }));

  }

  track(idBase: number, idIndex: number, cameraId: string, url: string) {
    const trackObjectState: TrackObjectState = {
      descriptor: true,
      idBase,
      idIndex,
      onlyFaceId: false,
      cameraId,
      url,
      multi: false,
      alert: true,
    };
    this.store$.dispatch(TrackObjectActions.setTrackObject({ trackObjectState }));
    this.router.navigateByUrl('/cameras/track');
  }

  public share() {
    this.dialog
      .open(ShareAccessComponent, {
        panelClass: 'thumbnail-dialog-wrapper',
        width: '502px',
        maxHeight: '802px',
        data: {
          title: 'alert',
          name: this.alert.eventName,
          entityId: this.alert._id,
          type: GrantedAccessType.ALERT,
        },
      })
      .afterClosed();

    // const grantedAccess: GrantedAccessModel.CreateGrantedAccessRequest = {
    //   accessPeriod: 0,
    //   allowDownload: true,
    //   emails: [],
    //   isPassword: false,
    //   message: '',
    //   notifyEnabled: false,
    //   password: '',
    //   phones: [],
    //   type: GrantedAccessType.ALERT,
    //   entityId: this.alert._id,
    //   name: this.alert.eventName,
    // };
    // this.store$.dispatch(GrantedAccessActions.createGrantedAccess({ grantedAccess }));
    // this.store$.dispatch(
    //   ArchiveAction.updateAddArchiveModalConfig({
    //     property: 'disableSelectedCamera',
    //     disable: true,
    //   }),
    // );
    this.store$.dispatch(
      ArchiveAction.changeArchiveProperty({
        property: 'start',
        value: moment(this.alert.timestamp)
          .subtract(10, 'seconds')
          .toString(),
      }),
    );
    this.store$.dispatch(
      ArchiveAction.changeArchiveProperty({
        property: 'selectedCamera',
        value: this.alert.selectedCamera,
      }),
    );
    this.store$.dispatch(
      ArchiveAction.changeArchiveProperty({
        property: 'end',
        value: moment(this.alert.timestamp)
          .add(10, 'seconds')
          .toString(),
      }),
    );
  }

  public get getEdgePulsationStatus(): PulsationModels.ComponentStatus {
    if (!this.edgePulsationStatus || !this.edgePulsationStatus?.timestamp) {
      return PulsationModels.ComponentStatus.Offline;
    }

    if (!this.edgePulsationStatus?.status) {
      return PulsationModels.ComponentStatus.Unknown;
    }
    return this.edgePulsationStatus.status;
  }

  public get getCameraPulsationStatus(): PulsationModels.ComponentStatus {
    if (!this.cameraPulsationStatus || !this.cameraPulsationStatus?.timestamp) {
      return PulsationModels.ComponentStatus.Offline;
    }

    if (!this.cameraPulsationStatus?.status) {
      return PulsationModels.ComponentStatus.Unknown;
    }
    return this.cameraPulsationStatus.status;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['alert']) {
    }
  }

  public get userTimezoneAbbreviation() {
    const userZone = moment()
      .tz(moment.tz.guess())
      .format('z');
    return userZone;
  }

  public get isAckPermission() {
    return this.store$.pipe(select(PermissionSelectors.checkAccessPermissions([PermissionModel.Permissions.AlertAck], [])),
      take(1));
  }

  public get isShareAlertPermission() {
    return this.store$.pipe(select(PermissionSelectors.checkAccessPermissions([PermissionModel.Permissions.AlertShare], [])),
      take(1));
  }

  protected readonly bottom = bottom;
}
