import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, startWith, take } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { LetDirective } from '@ngrx/component';
import { UiKitModule } from '../../shared/ui-kit/ui-kit.module';
import { ActiveOrganization } from '@models/organization.model';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { LocationRowComponent } from './components/location-row/location-row.component';
import { SelectedCamera } from '@models/alert-events.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { LocationSelectors } from '@states/location/location.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EdgeCamera } from '../../cameras/camera.model';
import { CameraSelectorModalModels } from './models/models';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Location } from '../../locations/location.model';
import { CameraRowComponent } from './components/camera-row/camera-row.component';
import { smartSearch } from '../../helpers/common.helpers';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';

@UntilDestroy()
@Component({
  selector: 'app-camera-selector-modal',
  standalone: true,
  imports: [
    LetDirective,
    UiKitModule,
    MatCheckbox,
    NgIf,
    LocationRowComponent,
    NgForOf,
    AsyncPipe,
    MatDialogClose,
    ReactiveFormsModule,
    CameraRowComponent,
    NgScrollbarModule,
  ],
  templateUrl: './camera-selector-modal.component.html',
  styleUrls: ['./camera-selector-modal.component.scss', './styles/index.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraSelectorModalComponent implements OnInit {
  public selectLocationsMap$: Observable<
    {
      allCameras: Dictionary<CameraSelectorModalModels.Camera>,
      locations: Dictionary<CameraSelectorModalModels.Location>
    }
  > = this.store$.pipe(select(LocationSelectors.selectLocationCamerasSelectorModal));

  public filteredCameras$: Observable<Location.LocationCameraItem[]>;

  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );


  public selectedCameras$: BehaviorSubject<Dictionary<SelectedCamera>> = new BehaviorSubject<Dictionary<SelectedCamera>>({});
  public collapsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isMulti$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public searchControl = new FormControl();

  private locationsMap: Dictionary<CameraSelectorModalModels.Location> = {};
  private totalCamerasCount: number;
  private allCameras: Dictionary<EdgeCamera.CameraItem>;
  protected readonly Object = Object;

  constructor(
    private store$: Store<AppState>,
    private modalRef: MatDialogRef<CameraSelectorModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: CameraSelectorModalModels.DialogData,
  ) {
  }

  public ngOnInit() {

    this.filteredCameras$ = combineLatest([
      this.selectLocationsMap$,
      this.searchControl.valueChanges
        .pipe(startWith('')),
    ])
      .pipe(
        /**
         * locations - initial location list
         * queryFilter - query filter for search by name
         */
        map(([
               locations,
               queryFilter,
             ]) => smartSearch(Object.values(locations.allCameras ?? {}), queryFilter, 'name'),
        ),
      );
    this.selectLocationsMap$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(res => {
        this.locationsMap = res.locations;
        this.totalCamerasCount = Object.keys(res.allCameras ?? {}).length;
        this.allCameras = res.allCameras;
      });


    if (typeof this.data.multi !== 'undefined') {
      this.isMulti$.next(this.data.multi);
    }

    if (this.data.selectedCameras) {
      this.setSelectedCameras(this.data.selectedCameras);
    }
  }

  public selectCamera(camera: SelectedCamera) {
    const selectedCameras = this.selectedCameras$.getValue();
    if (!selectedCameras[camera.cameraId]) {
      selectedCameras[camera.cameraId] = camera;
    } else {
      delete selectedCameras[camera.cameraId];
    }
    this.setSelectedCameras(selectedCameras);
  }

  public selectEdge(value: { edgeId: string, locationId: string; checked: boolean }) {
    const edgesCameras = this.locationsMap[value.locationId].edges[value.edgeId].cameras ?? {};
    const selectedCameras = this.selectedCameras$.getValue();
    if (value.checked) {
      Object.values(edgesCameras)
        .forEach(camera => {
          selectedCameras[camera.edgeOnly.cameraId] = { cameraId: camera.edgeOnly.cameraId, edgeId: camera.edgeId, locationId: camera.locationId };
        });
    } else {
      Object.values(edgesCameras)
        .forEach(camera => {
          delete selectedCameras[camera.edgeOnly.cameraId];
        });
    }
    this.setSelectedCameras(selectedCameras);
  }

  public selectLocation(value: { locationId: string; checked: boolean }) {
    const selectedCameras = this.selectedCameras$.getValue();
    const location = this.locationsMap[value.locationId];
    let locationCameras = Object.values(location.locationCameras ?? {});
    if (value.checked) {
      Object.values(locationCameras)
        .forEach(camera => {
          selectedCameras[camera.edgeOnly.cameraId] = { cameraId: camera.edgeOnly.cameraId, edgeId: camera.edgeId, locationId: camera.locationId };
        });
    } else {
      Object.values(locationCameras)
        .forEach(camera => {
          delete selectedCameras[camera.edgeOnly.cameraId];
        });
    }
    this.setSelectedCameras(selectedCameras);
  }

  public select() {
    const selectedCameras = this.selectedCameras$.getValue();
    this.modalRef.close(selectedCameras);
  }

  public setSelectedCameras(cameras: Dictionary<SelectedCamera>) {
    /**
     * IMPORTANT TO ALWAYS CREATE NEW OBJECT CAUSE DETECTION CHANGES STRATEGY ONPUSH
     */
    this.selectedCameras$.next({ ...cameras });
  }

  public get selected() {
    const selectedCameras = this.selectedCameras$.getValue();
    return Object.keys(selectedCameras).length === this.totalCamerasCount;
  }

  public selectAllCameras(ev: MatCheckboxChange) {
    const selectedCameras: Dictionary<SelectedCamera> = {};
    if (ev.checked) {
      Object.values(this.allCameras)
        .forEach(camera => {
          selectedCameras[camera.edgeOnly.cameraId] = { cameraId: camera.edgeOnly.cameraId, edgeId: camera.edgeId, locationId: camera.locationId };
        });
      this.setSelectedCameras(selectedCameras);
    } else {
      this.setSelectedCameras({});
    }
  }

  public searchLocations(query: string) {
    this.searchControl.patchValue(query);
  }

  public trackBy(index: number, locaton: CameraSelectorModalModels.Location) {
    return locaton._id;
  }

  public trackByCameraId(index: number, camera: Location.LocationCameraItem) {
    return camera.edgeOnly.cameraId;
  }

  public toggleExpand() {
    const isCollapsed = this.collapsed$.getValue();
    this.collapsed$.next(!isCollapsed);
  }
}
