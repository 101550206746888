import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, exhaustMap, share, switchMap } from 'rxjs';
import * as SharedActions from '@states/shared/shared.actions';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { AlertsV2StoreActions } from '@states/alerts-v2/alerts-v2.action-types';
import { AlertEventsService } from '../../development/alert-events.service';
import { ActionType, AlertCategory, AlertsV2People, AlertsV2Plates, AlertV2SelectedFlow, AlertV2SendModel, IdentificationType, SafetyType, StatusType, TrackingType } from '@models/alerts-v2.model';
import { SessionDataAction } from '@enums/session-data.enum';
import { AlertEventTrafficControl } from '@models/alert-events.model';
import * as _ from 'lodash';
import { CameraLookup } from '@models/camera.model';
import { EdgeCamera } from '../../cameras/camera.model';
import { IntegrationsModel } from '@models/integrations.model';
import Providers = IntegrationsModel.Providers;

@Injectable()
export class AlertsV2Effect {

  public saveAlertEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertsV2StoreActions.saveAlert),
      exhaustMap(() => [SharedActions.setIsSaving({ isSaving: true }), AlertsV2StoreActions.sendAlert()]),
    ),
  );

  validateLineCrossing(cameras: CameraLookup[]): boolean {
    for(let camera of cameras) {
      if (!camera?.lineCrossing || !camera?.lineCrossing?.p1 || !camera?.lineCrossing?.p2) {
        return false;
      }
    }
    return true;
  }

  validateMultipleLineCrossing(cameras: EdgeCamera.CameraItem[]): boolean {
    for(let camera of cameras) {
      if (!camera?.lineCrossing) {
        return false;
      }
      for(let line of (camera?.lineCrossing as AlertEventTrafficControl).lines) {
        if (!line.p1 || !line.p2) {
          return false;
        }
      }
    }
    return true;
  }

  validateSafety(flow: AlertV2SelectedFlow): boolean {
    let valid = true;
    const value = flow.formValue;
    switch (flow.flowType) {
      case SafetyType.Door:
        if (!value?.doors?.length) {
          valid = false;
        }
        break;
      case SafetyType.SpeedLimit:
        if (!value?.objects?.length || !value?.camera[0]?.trafficControl || !value?.duration) {
          valid = false;
        }
        break;
      case SafetyType.ZoneProtection:
        if (!value?.objects?.length || !value?.duration && value?.duration !== 0) {
          valid = false;
        }
        break;
      case SafetyType.Trespassing:
        if (!value?.objects?.length || !this.validateMultipleLineCrossing(value?.camera)) {
          valid = false;
        }
        break;
      case SafetyType.Tailgating:
        if (!value?.objects?.length || !this.validateLineCrossing(value?.camera)) {
          valid = false;
        }
        break;
      case SafetyType.Proximity:
        if (value?.objects?.length < 2 || !value?.duration && value?.duration !== 0) {
          valid = false;
        }
        break;
      default:
        break;

    }
    return valid;
  }

  validateTracking(flow: AlertV2SelectedFlow): boolean {
    let valid = true;
    const value = flow.formValue;
    switch (flow.flowType) {
      case TrackingType.Loitering:
        if (value?.objects?.length === 0 || (!value?.duration && value.duration !== 0) || (!value?.durationUnit && value.durationUnit !== 0)) {
          valid = false;
        }
        break;
      case TrackingType.LineCrossing:
        if (value?.objects?.length === 0 || !this.validateMultipleLineCrossing(value?.camera)) {
          valid = false;
        }
        break;
      case TrackingType.Occupancy:
        if (value?.amount?.length === 0) {
          valid = false;
        }
        break;
      default:
        if (value?.objects?.length === 0) {
          valid = false;
        }
        break;

    }
    return valid;
  }

  validateIdentification(flow: AlertV2SelectedFlow): boolean {
    let valid = true;
    const value = flow.formValue;
    switch (flow.flowType) {
      case IdentificationType.LPR:
        const plates: AlertsV2Plates = value?.plates;
        if (!plates?.list?.length) {
          valid = false;
        }
        break;
      case IdentificationType.FaceDetection:
        const people: AlertsV2People = value?.people;
        if (!people?.list?.length) {
          valid = false;
        }
        break;
      default:
        break;

    }
    return valid;
  }

  validateStatus(flow: AlertV2SelectedFlow): boolean {
    let valid = true;
    const value = flow.formValue;
    switch (flow.flowType) {
      case StatusType.Edge:
        if (!value.edges?.length || !value?.duration && value?.duration !== 0) {
          valid = false;
        }
        break;
      default:
        if (!value?.duration && value?.duration !== 0) {
          valid = false;
        }
        break;
    }
    return valid;
  }

  public alertValid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[
        AlertsV2StoreActions.setName,
        AlertsV2StoreActions.setSelectedFlow,
        AlertsV2StoreActions.setActions,
        AlertsV2StoreActions.setSettings,
      ]),
      withLatestFrom(this.store$.pipe(select(state => state.alertsV2State))),
      switchMap(([, alertsV2State]) => {
        let valid = true;
        let nameValid = true;
        let flowValid = true;
        let actionsValid = true;

        if (!alertsV2State.name) {
          nameValid = false;
        }

        // Flow validations
        const flow = alertsV2State.selectedFlow;

        switch (flow.category) {
          case AlertCategory.Tracking:
            flowValid = this.validateTracking(flow);
            break;
          case AlertCategory.Safety:
            flowValid = this.validateSafety(flow);
            break;
          case AlertCategory.Identification:
            flowValid = this.validateIdentification(flow);
            break;
          case AlertCategory.Status:
            flowValid = this.validateStatus(flow);
            break;

        }

        // Action validation

        const actions = alertsV2State.actions;

        for(let action of actions) {
          switch (+action.actionType) {
            case ActionType.NOTIFY:
              break;
            case ActionType.GPIO:
              if (!action?.formValue['gpio'] || action?.formValue['gpio'] === 0) {
                actionsValid = false;
              }
              if (!action?.formValue['duration'] || action?.formValue['duration'] === 0) {
                actionsValid = false;
              }
              if (!action?.formValue['level'] || action?.formValue['level'] === 0) {
                actionsValid = false;
              }
              break;
            case ActionType.SEND_MESSAGE:
              if (
                !!action?.formValue &&
                !action?.formValue['httpRequest']['address']
              ) {
                actionsValid = false;
              }
              break;
            case ActionType.INTEGRATION_PROVIDER_ACTION:
              const provider: IntegrationsModel.Providers = action.formValue.provider;
              switch (provider) {
                case Providers.Slack:
                  if (!action.formValue.params.channel) {
                    actionsValid = false;
                  }
                  break;
              }
              break;
          }
        }

        if (flow.category === AlertCategory.Status && flow.flowType === StatusType.Edge) {
        } else {
          if (!flow?.formValue?.camera[0]?.cameraId) {
            flowValid = false;
          }
        }
        if (alertsV2State.valid !== (flowValid && nameValid && actionsValid)) {
          return [AlertsV2StoreActions.setValid({ valid: flowValid && nameValid && actionsValid })];
        }
        return [SharedActions.doNothing()];
      }),
    ));

  public sendAlertEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AlertsV2StoreActions.sendAlert),
      withLatestFrom(this.store$.pipe(select(state => state.alertsV2State)), this.store$.pipe(select(state => state.alertEventsState))),
      mergeMap(([, alertsV2State, alertEventsState]) => {
        // Validate
        const send: AlertV2SendModel = {
          name: alertsV2State.name,
          actions: alertsV2State.actions,
          selectedFlow: alertsV2State.selectedFlow,
          settings: alertsV2State.settings,
        };

        const operation = alertsV2State?.selectedEvent?._id ?
          this.alertEventsService.updateAlertEventsV2(alertsV2State?.selectedEvent?._id, send) :
          this.alertEventsService.createAlertV2(send);

        return operation.pipe(
          mergeMap(res => {
            const actions = [];
            for(let result of res) {
              actions.push(SharedActions.subscribeToSessionStatus({
                token: result?.token?.session,
                sessionDataAction: SessionDataAction.sendAlert,
                params: {
                  msTimeout: 40000,
                },
              }));
            }

            return [
              SharedActions.showMessage({
                success: `Alert has been ${alertsV2State?.selectedEvent?._id ? 'updated' : 'created'}`,
              }),
              SharedActions.setIsSaving({ isSaving: false }),
              AlertsV2StoreActions.saveAlertSuccess(),
              ...actions,
            ];
          }),
          catchError(err => {
            return [
              SharedActions.showMessage({
                error: err.error?.message ?? 'Error creating alert',
              }),
              SharedActions.setIsSaving({ isSaving: false }),
            ];
          }),
        );
      }),
      share(),
    ));


  public alertChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[
        AlertsV2StoreActions.setName,
        AlertsV2StoreActions.setSelectedFlow,
        AlertsV2StoreActions.setActions,
        AlertsV2StoreActions.setSettings,
      ]),
      withLatestFrom(this.store$.pipe(select(state => state.alertsV2State)), this.store$.pipe(select(state => state.alertEventsState))),
      switchMap(([, alertsV2State, alertEventsState]) => {
        const selectedEvent = _.cloneDeep(alertsV2State.selectedEvent);
        if (!selectedEvent) {
          return [AlertsV2StoreActions.setChanged({ changed: false })];
        }
        if (selectedEvent?.selectedFlow?.formValue?.schedule === undefined) {
          selectedEvent.selectedFlow.formValue.schedule = undefined;
        }
        for(let action of selectedEvent.actions) {
          if (action.actionType === null) {
            action.formValue = undefined;
          }
        }
        // const eventId = selectedEvent?._id;
        // const origEvent = alertEventsState.alertEvents.find(e => e._id === eventId) as unknown as EventV2Document;
        if (!selectedEvent || (_.isEqual(selectedEvent.name, alertsV2State.name) &&
          _.isEqual(selectedEvent.selectedFlow, alertsV2State.selectedFlow) &&
          _.isEqual(selectedEvent.settings, alertsV2State.settings) &&
          _.isEqual(selectedEvent.actions, alertsV2State.actions))
        ) {
          return [AlertsV2StoreActions.setChanged({ changed: false })];
        }
        return [AlertsV2StoreActions.setChanged({ changed: true })];
      })));

  constructor(private actions$: Actions, private store$: Store<AppState>, private alertEventsService: AlertEventsService) {
  }
}
