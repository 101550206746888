import { createAction, props } from '@ngrx/store';
import { MfaModel } from '@models/mfa.model';

export const resetToInitialState = createAction('[MFA] Reset to Initial state');

export const getUserMfaMethods = createAction('[MFA] Get User MFA methods');
export const getUserMfaMethodsSuccess = createAction('[MFA] Get User MFA methods Success', props<{ documents: MfaModel.Document[] }>());
export const getUserMfaMethodsFail = createAction('[MFA] Get User MFA methods Fail');

export const removeUserMfaMethod = createAction('[MFA] Remove User MFA method', props<{ id: string }>());
export const removeUserMfaMethodSuccess = createAction('[MFA] Remove User MFA method success', props<{ id: string }>());
export const removeUserMfaMethodFail = createAction('[MFA] Remove User MFA method fail', props<{ id: string }>());

export const checkUserMfaEnabledSuccess = createAction('[MFA] Check Remove User MFA enabled Success', props<{ enabled: boolean, msg: string }>());

export const createMfa = createAction('[MFA] Create MFA');
export const createMfaSuccess = createAction('[MFA] Create MFA success', props<{ qr: string; mfa: MfaModel.Document }>());
export const createMfaFail = createAction('[MFA] Create MFA fail');

