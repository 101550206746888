import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, startWith } from 'rxjs';
import { UiLocationSelectItem } from '@models/ui.model';
import { ActiveOrganization } from '@models/organization.model';
import { select, Store } from '@ngrx/store';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import { AppState } from '../../store/app.state';
import { Dictionary } from '@ngrx/entity/src/models';
import { EdgeCamera } from '../../cameras/camera.model';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { alertEventSettingPriority } from '@consts/alerts-v2.const';
import { AlertsV2SettingPriority, AlertV2Document } from '@models/alerts-v2.model';
import { LocationSelectors } from '@states/location/location.selector-types';
import { Location } from '../../locations/location.model';
import { SelectedCamera } from '@models/alert-events.model';
import { MsgBoxType } from '../../shared/msg-box/msg-box.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertEventSelectionResult } from './components/alert-row/alert-row.component';
import { defaultAlertTileConfig, defaultCameraTileConfig } from '@consts/wall.const';
import { selectAlert, selectAlOrganizationAlerts, selectCamera, selectLocation } from './helpers/camera-alert.selector.helper';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { WallModelV2 } from '@models/wall.model';
import CameraTileConfig = WallModelV2.CameraTileConfig;
import AlertTileConfig = WallModelV2.AlertTileConfig;
import WallPosition = WallModelV2.WallPosition;
import WallPositionEvent = WallModelV2.WallPositionEvent;

export declare interface CameraAlertSelectorData {
  tile: {
    cameraTileConfig: CameraTileConfig;
    alertTileConfig: AlertTileConfig;
    data: WallPosition
  },
  maxSelected: number;
  tileIndex: number,
  alertSelectionEnabled: boolean;
}


export declare interface CameraAlertSelectorResult {
  selectedCameras?: SelectedCamera[],
  selectedEvents?: WallPositionEvent[],
  selectedEventCameras?: SelectedCamera[];
  cameraTileConfig?: CameraTileConfig;
  alertTileConfig?: AlertTileConfig;
}

@Component({
  selector: 'app-camera-alert-selector-modal',
  templateUrl: './camera-alert-selector-modal.component.html',
  styleUrls: ['./camera-alert-selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraAlertSelectorModalComponent implements OnInit {
  public selectAllCamerasGroupByLocation$: Observable<UiLocationSelectItem[]>;
  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  public selectCamerasLookup$: Observable<Dictionary<EdgeCamera.CameraItem>> = this.store$.pipe(
    select(CameraSelectors.selectCamerasLookup),
  );

  public selectCamerasEventsLookup$: Observable<AlertV2Document[]> = this.store$.pipe(
    select(CameraSelectors.selectCamerasEventsArray),
  );

  public selectEventsLookup$: Observable<Dictionary<AlertV2Document>> = this.store$.pipe(
    select(CameraSelectors.selectEventsLookup),
  );

  public selectAllLocations$: Observable<Location.LocationItem[]> = this.store$.pipe(
    select(LocationSelectors.selectAllLocations),
  );

  public selectAllCameras$: Observable<EdgeCamera.CameraItem[]> = this.store$.pipe(
    select(CameraSelectors.selectAllCameras),
  );

  public totalCamerasOrganization$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public isSettingsOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public availableAlerts$: Observable<AlertV2Document[]>;

  public isCamerasVisibleControl: UntypedFormControl = new UntypedFormControl(true);

  public searchFilter: UntypedFormControl = new UntypedFormControl(null);
  public viewByLocationControl: UntypedFormControl = new UntypedFormControl(false);

  public alertPriorityFilter: UntypedFormControl = new UntypedFormControl(null);
  public alertLocationFilter: UntypedFormControl = new UntypedFormControl(null);
  public alertCameraFilter: UntypedFormControl = new UntypedFormControl(null);

  public priorityFilter = [
    {
      name: alertEventSettingPriority[AlertsV2SettingPriority.High],
      value: AlertsV2SettingPriority.High,
    },
    {
      name: alertEventSettingPriority[AlertsV2SettingPriority.Medium],
      value: AlertsV2SettingPriority.Medium,
    },
    {
      name: alertEventSettingPriority[AlertsV2SettingPriority.Low],
      value: AlertsV2SettingPriority.Low,
    },
  ];

  public alertSettingsForm = new UntypedFormGroup({
    sound: new UntypedFormControl(defaultAlertTileConfig.sound),
    display: new UntypedFormControl(defaultAlertTileConfig.display),
    titleColor: new UntypedFormControl(defaultAlertTileConfig.titleColor),
    picInPic: new UntypedFormControl(defaultAlertTileConfig.picInPic),
    picInPicPos: new UntypedFormControl(defaultAlertTileConfig.picInPicPos),
    duration: new UntypedFormControl(defaultAlertTileConfig.duration),
  });

  public cameraSettingsForm = new UntypedFormGroup({
    titleColor: new UntypedFormControl(defaultCameraTileConfig.titleColor),
    displayCameraName: new UntypedFormControl(defaultCameraTileConfig.displayCameraName),
  });

  public selectedCameras: { [cameraId: string]: SelectedCamera } = {};
  public selectedAlertEventsV2: { [eventId: string]: WallPositionEvent[] } = {};
  public alertTypes = MsgBoxType;
  private options: { label: string, value: boolean }[] = [
    { label: 'Cameras', value: true },
    { label: 'Alerts', value: false },
  ];

  public filteredOptions$: BehaviorSubject<{ label: string, value: boolean }[]> = new BehaviorSubject<{ label: string; value: boolean }[]>([]);

  constructor(private store$: Store<AppState>,
              private dialogRef: MatDialogRef<CameraAlertSelectorModalComponent>,
              @Inject(MAT_DIALOG_DATA)
              public data: CameraAlertSelectorData) {
  }

  public ngOnInit(): void {
    const initialValue = this.data?.tile?.data;
    let options = [];
    if (!this.data.alertSelectionEnabled) {
      options.push(this.options[0]);
    } else {
      options = this.options;
    }
    this.filteredOptions$.next(options);

    if (initialValue) {
      if (initialValue?.events) {
        initialValue.events.forEach(alertEvent => {
          if (this.selectedAlertEventsV2[alertEvent.eventId]) {
            this.selectedAlertEventsV2[alertEvent.eventId].push(alertEvent);
          } else {
            this.selectedAlertEventsV2[alertEvent.eventId] = [alertEvent];
          }
        });
        this.isCamerasVisibleControl.setValue(false);
      }
      if (initialValue.camerasEvents) {
        this.isCamerasVisibleControl.setValue(false);
        this.viewByLocationControl.setValue(true);
        initialValue.camerasEvents.map(camera => {
          this.selectedCameras[camera.cameraId] = camera;
        });
      }
      if (initialValue.cameraId) {
        const selectedCamera: SelectedCamera = {
          cameraId: initialValue.cameraId,
          edgeId: initialValue.edgeId,
          locationId: initialValue.locationId,
        };
        this.selectedCameras[selectedCamera.cameraId] = selectedCamera;
      }
    }

    if (this.data?.tile?.cameraTileConfig) {
      this.cameraSettingsForm.patchValue(this.data?.tile.cameraTileConfig);
    }

    if (this.data?.tile?.alertTileConfig) {
      this.alertSettingsForm.patchValue(this.data?.tile.alertTileConfig);
    }

    this.selectAllCamerasGroupByLocation$ = combineLatest([
      this.store$.pipe(select(CameraSelectors.selectAllCamerasGroupByLocation())),
      this.searchFilter.valueChanges
        .pipe(startWith(null)),
    ])
      .pipe(map(([locations, query]) => {
        let filteredLocationCameras = locations;
        let totalCamerasOrg = 0;
        locations.forEach(location => {
          totalCamerasOrg += Object.keys(location.cameras).length;
        });
        this.totalCamerasOrganization$.next(totalCamerasOrg);
        if (query) {
          filteredLocationCameras = filteredLocationCameras.map(location => {
            return {
              ...location,
              cameras: location.cameras.filter(camera => {
                return camera.edgeOnly.name.toUpperCase()
                  .indexOf(query.toUpperCase()) > -1;
              }),
            };
          });
        }
        return filteredLocationCameras;
      }));

    this.availableAlerts$ = combineLatest([
      this.selectCamerasEventsLookup$,
      this.alertPriorityFilter.valueChanges
        .pipe(startWith(null)),
      this.alertLocationFilter.valueChanges
        .pipe(startWith(null)),
      this.alertCameraFilter.valueChanges
        .pipe(startWith(null)),
      this.searchFilter.valueChanges
        .pipe(startWith(null)),
    ])
      .pipe(map(([alerts, priorities, locationIds, cameraIds, query]) => {
        const groupIds = {};
        let filteredAlerts: AlertV2Document[] = [];
        alerts.forEach(alert => {
          if (!groupIds[alert.groupId]) {
            filteredAlerts.push(alert);
            groupIds[alert.groupId] = true;
          }
        });
        if (priorities?.length) {
          filteredAlerts = filteredAlerts.filter(alertEvent => {
            return priorities.includes(alertEvent.settings.priority);
          });
        }
        if (locationIds?.length) {
          filteredAlerts = filteredAlerts.filter(alertEvent => {
            const cameras = Object.values(alertEvent.cameraSync);
            return cameras.some(camera => locationIds.includes(camera.locationId),
            );
          });
        }
        if (cameraIds?.length) {
          filteredAlerts = filteredAlerts.filter(alertEvent => {
            const cameras = Object.keys(alertEvent.cameraSync);
            return cameras.some(cameraId => cameraIds.includes(cameraId));
          });
        }
        if (query) {
          filteredAlerts = filteredAlerts.filter(alertEvent => {
            return alertEvent.name.toUpperCase()
              .indexOf(query.toUpperCase()) > -1;
          });
        }
        return filteredAlerts;
      }));

    this.viewByLocationControl.valueChanges
      .subscribe(res => {
        this.selectedCameras = {};
        this.selectedAlertEventsV2 = {};
      });
  }

  public search(query: string): void {
    this.searchFilter.patchValue(query);
  }

  public submit(): void {
    let selectedAlertEvents: WallPositionEvent[] = [];
    Object.values(this.selectedAlertEventsV2)
      .forEach(position => {
        selectedAlertEvents = selectedAlertEvents.concat(position);
      });

    /**
     * If camera selector enabled return cameras
     */
    const result: CameraAlertSelectorResult = {};

    if (this.isCamerasVisibleControl.value) {
      result.selectedCameras = Object.values(this.selectedCameras);
      result.cameraTileConfig = this.cameraSettingsForm.value;
    } else {
      if (this.viewByLocationControl.value) {
        result.selectedEventCameras = Object.values(this.selectedCameras);
      } else {
        result.selectedEvents = selectedAlertEvents;
      }
      result.alertTileConfig = this.alertSettingsForm.value;
    }
    this.dialogRef.close(result);
  }

  public onCameraSelected(selectedCamera: SelectedCamera): void {
    this.selectedCameras = selectCamera(selectedCamera, this.selectedCameras);
  }

  public onLocationSelected(result: { location: UiLocationSelectItem, selected: boolean }): void {
    this.selectedCameras = selectLocation(result, this.selectedCameras);
  }

  public onOrganizationSelected(event: MatCheckboxChange, locations: UiLocationSelectItem[]): void {
    const isSelected = event.checked;
    locations.forEach(location => {
      location.cameras.map(camera => {
        if (isSelected) {
          this.selectedCameras =
            {
              ...this.selectedCameras,
              [camera.edgeOnly.cameraId]: { cameraId: camera.edgeOnly.cameraId, edgeId: camera.edgeId, locationId: camera.locationId },
            };
        } else {
          delete this.selectedCameras[camera.edgeOnly.cameraId];
          this.selectedCameras = { ...this.selectedCameras };
        }
      });
    });
  }

  public onAlertSelected(result: AlertEventSelectionResult): void {
    this.selectedAlertEventsV2 = selectAlert(result, this.selectedAlertEventsV2);
  }

  get selectedCamerasCount(): number {
    return Object.values(this.selectedCameras).length;
  }


  public onOrganizationAlertsSelected(event: MatCheckboxChange, alertsLookup: Dictionary<AlertV2Document>): void {
    this.selectedAlertEventsV2 = selectAlOrganizationAlerts(event, alertsLookup);
  }

  public openSettings(): void {
    this.isSettingsOpen$.next(true);
  }

  public closeSettings(): void {
    this.isSettingsOpen$.next(false);
  }

  public saveAlertSettings(): void {
    this.closeSettings();
  }

  public saveCameraSettings(): void {
    this.closeSettings();
  }

}
