import { createAction, props } from '@ngrx/store';
import { AppUser } from '../../../user/user.model';
import { AuthResponseFromUrl } from '@enums/shared.enum';
import { Invite } from '@models/invite.model';
import { Auth0 } from '../../../authentication/auth0.model';

enum UserActionTypes {
  CreateOrGetUserProfile = '[Users API] CreateOrGetUserProfile',
  CreateOrGetUserProfileSuccess = '[Users API] CreateOrGetUserProfile Success',
  CreateOrGetUserProfileFail = '[Users API] CreateOrGetUserProfile Fail',
  UpdateUserProfile = '[Users API] UpdateUserProfile',
  UpdateUserProfileSuccess = '[Users API] UpdateUserProfileSuccess Success',
  UpdateUserProfileFail = '[Users API] UpdateUserProfileFail Fail',
  SessionIdentity = '[Users API] SessionIdentity',
}

export const SessionIdentity = createAction(UserActionTypes.SessionIdentity);

export const CreateOrGetUserProfile = createAction(UserActionTypes.CreateOrGetUserProfile, props<AppUser.CreateOrGetUserProfileRequest>());

export const CreateOrGetUserProfileSuccess = createAction(
  UserActionTypes.CreateOrGetUserProfileSuccess,
  props<{ payload: AppUser.CreateOrGetUserProfileResponse }>(),
);

export const CreateOrGetUserProfileFail = createAction(UserActionTypes.CreateOrGetUserProfileFail, props<{ message?: string }>());

export const UpdateUserProfile = createAction(UserActionTypes.UpdateUserProfile, props<AppUser.UpdateUserProfileRequest>());

export const UpdateUserProfileSuccess = createAction(
  UserActionTypes.UpdateUserProfileSuccess,
  props<{ payload: AppUser.UpdateUserProfileResponse }>(),
);

export const UpdateUserProfileFail = createAction(UserActionTypes.UpdateUserProfileFail, props<{ message?: string }>());

export const setTimezone = createAction('[USER] Set Timezone', props<{ timezone: string }>());

export const setPhone = createAction('[USER] Set Phone', props<{ phone: string }>());
export const setPhoneVerified = createAction('[USER] Set Phone Verified', props<{ isPhoneVerified: boolean }>());

/**
 * new need to discuss
 */
export const updateUser = createAction('[USER] Update');
export const getUserMfaStatus = createAction('[USER] Get User Mfa Status');
export const getUserMfaStatusSuccess = createAction('[USER] Get User Mfa Status Success', props<{ res: { userMfa: boolean, orgsMfa: boolean } }>());
export const getUserMfaStatusFail = createAction('[USER] Get User Mfa Status Fail');

export const changePassword = createAction('[USER] Change password', props<{ password: string; token: string }>());
export const changePasswordServer = createAction('[USER] Change password Server', props<{ password: string; token: string }>());
export const changePasswordSuccess = createAction('[USER] Change password Success');
export const changePasswordFail = createAction('[USER] Change password Fail', props<{ error: string }>());

export const resetPassword = createAction('[USER] Reset password', props<{ email: string }>());
export const resetPasswordServer = createAction('[USER] Reset password Server', props<{ email: string }>());
export const resetPasswordSuccess = createAction('[USER] Reset password Success');
export const resetPasswordFail = createAction('[USER] Reset password Fail', props<{ error: string }>());
export const resetPasswordSecured = createAction('[USER] Reset password Server Secured');

export const checkResetTokenIsValid = createAction('[USER] Check Reset Token Is Valid', props<{ token: string }>());
export const checkResetTokenIsValidServer = createAction('[USER]  Check Reset Token Is Valid Server', props<{ token: string }>());
export const checkResetTokenIsValidSuccess = createAction('[USER]  Check Reset Token Is Valid Success');
export const checkResetTokenIsValidFail = createAction('[USER]  Check Reset Token Is Valid Fail', props<{ error: string }>());

export const startLoginProcess = createAction('[USER] Start Login process', props<{ email: string, password: string, subUser?: AppUser.SubUser }>());
export const loginRequest = createAction('[USER] Login request', props<{ email: string, password: string, subUser?: AppUser.SubUser }>());
export const loginRequestSuccess = createAction('[USER] Login request Success');
export const loginRequestSuccessMfaRequired = createAction('[USER] Login request Success Mfa Required', props<{ mfaToken: string, qrCode: string }>());
export const loginRequestFail = createAction('[USER] Login request Fail', props<{ errorType: AuthResponseFromUrl, description: string }>());

export const startSignUpProcess = createAction('[USER] Start Sign up process', props<{ user: Auth0.UserSignUpRequest }>());
export const signUpRequest = createAction('[USER]  Sign up request', props<{ user: Auth0.UserSignUpRequest }>());
export const signUpRequestFail = createAction('[USER]  Sign up request fail', props<{ description: string, }>());

export const getSignUpInviteById = createAction('[USER] Get Sign Up Invite By Id', props<{ invitedId: string, }>());
export const getSignUpInviteByIdSuccess = createAction('[USER] Get Sign Up Invite By Id Success', props<{ invite: Invite, }>());
export const getSignUpInviteByIdFail = createAction('[USER] Get Sign Up Invite By Id Fail');

export const logout = createAction('[USER] Log out');
export const setIsLoginLoading = createAction('[USER] Set Is Login Loading', props<{ isLoginLoading: boolean }>());

export const startLoginSubUserProcess = createAction('[USER] Start Login Sub User process', props<{ email: string, password: string }>());
export const subUserLoginRequest = createAction('[USER] Sub User Login request', props<{ email: string, password: string }>());
export const subUserLoginRequestSuccess = createAction('[USER] Sub User Login request Success', props<{ subUser: AppUser.SubUser }>());
export const subUserLoginRequestFail = createAction('[USER] Sub User Login request Fail', props<{ errorType: AuthResponseFromUrl, description: string }>());

export const startChangeEmailProcess = createAction('[USER] start Change Email Process', props<{ email: string }>());
export const changeEmailRequest = createAction('[USER] Change Email Request', props<{ email: string }>());
export const changeEmailRequestSuccess = createAction('[USER] Change Email Request Success', props<{ id: string }>());
export const changeEmailRequestFail = createAction('[USER] Change Email Request Fail', props<{ error: string }>());

export const renewToken = createAction('[USER] Renew Token', props<{ orgId?: string }>());
export const renewTokenSuccess = createAction('[USER] Renew Token Success');
export const renewTokenFail = createAction('[USER] Renew Token Fail', props<{ error: string }>());


export const startChangePhoneProcess = createAction('[USER] start Change Phone Process', props<{ phone: string }>());
export const changePhoneRequest = createAction('[USER] Change Phone Request', props<{ phone: string }>());
export const changePhoneRequestSuccess = createAction('[USER] Change Phone Request Success', props<{ id: string }>());
export const changePhoneRequestFail = createAction('[USER] Change Phone Request Fail', props<{ error: string }>());

export const startUpdateUserProcess = createAction('[USER] Start Update User Process', props<{ user: Partial<AppUser.User> }>());
export const updateUserRequest = createAction('[USER] Update Request', props<{ user: Partial<AppUser.User> }>());
export const updateUserRequestSuccess = createAction('[USER]  Update Request Success');
export const updateUserRequestFail = createAction('[USER]  Update Request Fail', props<{ error: string }>());

export const startSubmit2FaCode = createAction('[USER] Start Submit 2Fa Code', props<{ code: string, mfaToken: string, isSubUser: boolean }>());
export const submit2FaCode = createAction('[USER]  Submit 2Fa Code', props<{ code: string, mfaToken: string, isSubUser: boolean }>());
export const submit2FaCodeFail = createAction('[USER]  Submit 2Fa Code Fail', props<{ error: string, }>());


export const createMfaIfNotExist = createAction('[USER] Create Mfa if not exists');
export const createMfaIfNotExistSuccess = createAction('[USER] Create Mfa if not exists Success', props<{ qrCodeBase64: string }>());
export const createMfaIfNotExistFail = createAction('[USER] Create Mfa if not exists Fail', props<{ error: string, }>());


