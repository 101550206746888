<div class="quality-selector-v2">
  <mat-select #selector [(ngModel)]="value" (ngModelChange)="changeQuality($event)"
              panelWidth="500" panelClass="resolution-selector-panel"
  >
    <mat-option [value]="Resolution.HQ">HQ</mat-option>
    <mat-option [value]="Resolution.MQ">MQ</mat-option>
    <mat-option [value]="Resolution.SQ">SQ</mat-option>
    <!--    <mat-option [value]="Resolution.AUTO">Auto</mat-option>-->
  </mat-select>
</div>
