import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WallCameraComponent } from '../wall-camera/wall-camera.component';
import WallCamera = WallModelV2.WallCamera;
import { WallModelV2 } from '@models/wall.model';

export interface CameraLiveViewDialogData {
  camera: WallCamera & { name: string };
}

@Component({
  selector: 'app-camera-live-view-dialog',
  templateUrl: './camera-live-view-dialog.component.html',
  styleUrls: ['./camera-live-view-dialog.component.scss'],
})
export class CameraLiveViewDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('wrapper')
  wrapper: ElementRef;

  @ViewChild('wallCamera')
  wallCamera: WallCameraComponent;

  @HostListener('window:resize', ['$event'])
  onResize() {
    const width = this.wrapper.nativeElement.clientWidth;
    // this.wrapper.nativeElement.style.minHeight = `${width * 9 / 16 + 100}px`;
  }

  constructor(
    public dialogRef: MatDialogRef<CameraLiveViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CameraLiveViewDialogData,
  ) {
  }

  ngOnInit(): void {
    this.data.camera.ready = false;
    this.data.camera.err = false;
  }

  ngAfterViewInit(): void {
    const width = this.wrapper.nativeElement.clientWidth;
    // this.wrapper.nativeElement.style.minHeight = `${width * 9 / 16 + 100}px`;
  }
}
