export const features = {
  alerts: {
    noData: {
      imgSrc: 'assets/alert.svg',
      title: 'Create alert',
      description: 'Alerts are the way to receive live alerts from your edge , click on the button to create a new alert',
      createButtonText: 'Create alert',
    },
    noResults: {
      imgSrc: 'assets/alert.svg',
      title: 'No Alerts Found',
      description: 'Try to search for a different name',
      createButtonText: null,
    },
  },
  alertsMonitoring: {
    noData: {
      imgSrc: 'assets/alert.svg',
      title: 'No new alerts',
      description: null,
      createButtonText: null,
    },
  },
  alertsViewList: {
    noData: {
      imgSrc: 'assets/alert.svg',
      title: 'No saved alerts',
      description: 'Saved alerts are the way to create specific alert views based on filters, click on the button to explore alerts and apply your own filters',
      createButtonText: 'Alerts monitoring',
    },
    noResults: {
      imgSrc: 'assets/alert.svg',
      title: 'No Saved Alerts Found',
      description: 'Try to search for a different name or click on the button to explore alerts and apply your own filters to create a view',
      createButtonText: 'Alerts monitoring',
    },
  },
  archives: {
    noData: {
      imgSrc: 'assets/archive-default.svg',
      title: 'Create archive',
      description: 'Archives are the way to download and share video recordings, click on the button to create a new archive',
      createButtonText: 'Create archive',
    },
    noResults: {
      imgSrc: 'assets/archive-default.svg',
      title: 'No Archives Found',
      description: 'Try to search with different filters ot click on the button to create a new archive',
      createButtonText: 'Create archive',
    },
  },
  walls: {
    noData: {
      imgSrc: 'assets/no-wall.svg',
      title: 'Create wall',
      description: 'Walls are the way to view your cameras and alerts, click on the button to add a new wall',
      createButtonText: 'Create wall',
    },
    noResults: {
      imgSrc: 'assets/no-wall.svg',
      title: 'No Walls Found',
      description: 'Try to search for a different name or click on the button to add a new wall',
      createButtonText: 'Create wall',
    },
  },
  cameraVpn: {
    noData: {
      imgSrc: 'assets/icons/warning.svg',
      title: 'Camera API access failed',
      imgTooltip: 'Supported vendors: Lumana, Hikvision, Uniview',
      description: '',
      createButtonText: 'Retry',
    },
  },
  people: {
    noSaved: {
      imgSrc: 'assets/people.svg',
      title: 'Add people',
      description: 'Add people to your organization from the unsaved list',
      createButtonText: 'Add person',
    },
    noResults: {
      imgSrc: 'assets/people.svg',
      title: 'No people',
      description: 'Add people by uploading an image',
      createButtonText: 'Add person',
    },
  },
  doors: {
    noSaved: {
      imgSrc: 'assets/add-items.svg',
      title: 'Add doors',
      description: 'Add doors that appear on your cameras',
    },
    noResults: {
      imgSrc: 'assets/add-items.svg',
      title: 'No doors',
      description: 'Add doors that appear on your cameras',
    },
  },
  customEvents: {
    noResults: {
      imgSrc: 'assets/add-items.svg',
      title: '',
      description: 'You don’t have any event tags',
      createButtonText: 'Create event tags',
    },
  },
  grantedAccess: {
    noResults: {
      imgSrc: 'assets/alert.svg',
      title: 'No results found',
      description: 'Try searching for different filters',
      createButtonText: null,
    },
  },
  organizationUsers: {
    noData: {
      imgSrc: 'assets/no-data/organization-users.svg',
      title: null,
      description: 'There are no users in your organization',
      createButtonText: 'Invite users',
    },
  },
  pendingInvitations: {
    noData: {
      imgSrc: 'assets/no-data/organization-users.svg',
      title: null,
      description: 'No pending invitations',
      createButtonText: 'Invite users',
    },
  },
  alertNotificationLogs: {
    noData: {
      imgSrc: 'assets/no-data/organization-users.svg',
      title: null,
      description: 'You don\'t have any alert logs yet',
      createButtonText: null,
    },
  },
  alertActivityLogs: {
    noData: {
      imgSrc: 'assets/no-data/organization-users.svg',
      title: null,
      description: 'There hasn\'t been any activity regarding this alert',
      createButtonText: null,
    },
  },
  vehicles: {
    noSaved: {
      imgSrc: 'assets/add-items.svg',
      title: 'Add vehicles',
      description: 'Add vehicles that appear on your cameras',
    },
    noResults: {
      imgSrc: 'assets/add-items.svg',
      title: 'No vehicles',
      description: 'Add vehicles that appear on your cameras',
    },
  },
  orgGroups: {
    noSaved: {
      imgSrc: 'assets/add-items.svg',
      title: 'No groups',
      description: 'Add group that appear on your cameras',
      createButtonText: 'Create group',
    },
    noResults: {
      imgSrc: 'assets/add-items.svg',
      title: 'No groups',
      description: 'Create groups to start organizing your database',
      createButtonText: 'Create group',
    },
  },
  dashboards: {
    noData: {
      imgSrc: 'assets/no-data/dashboards.svg',
      title: null,
      description: 'Create dashboards to visualize video and alert data.',
      createButtonText: 'Create dashboard',
    },
  },
  appleTv: {
    noData: {
      imgSrc: 'assets/no-data/dashboards.svg',
      title: null,
      description: 'Apple TV 4K platform - allow local streaming and webrtc.',
      createButtonText: 'Add apple TV stream',
    },
    noResults: {
      imgSrc: 'assets/alert.svg',
      title: 'No apple TV found',
      description: 'Try to search for a different name',
      createButtonText: null,
    },
  },
  userDevices: {
    noResults: {
      imgSrc: 'assets/archive-default.svg',
      title: 'No Devices Found',
    },
  },
  userMfa: {
    noResults: {
      imgSrc: 'assets/archive-default.svg',
      title: 'No MFA Found',
    },
  },
};


export const errorMsg = {
  incorrectPassword: 'The current password is incorrect',
  weakPassword: 'Password is too weak',
  shortPassword: 'Password is too short',
  userNotFound: 'Please contact sales@lumana.ai to login',
};
