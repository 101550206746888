import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiDatetimeRangePickerComponent } from './ui-datetime-range-picker/ui-datetime-range-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { UiDateTimeHeaderComponent } from './ui-datetime-range-picker/components/ui-date-time-header/ui-date-time-header.component';
import { MatIconModule } from '@angular/material/icon';
import { UiPrefixInputComponent } from './ui-prefix-input/ui-prefix-input.component';
import { UiButtonComponent } from './ui-button/ui-button.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { UiConfirmComponent } from './ui-confirm/ui-confirm.component';
import { UiAlertComponent } from './ui-alert/ui-alert.component';
import { UiCustomToastComponent } from './ui-custom-toast/ui-custom-toast.component';
import { UiCropComponent } from './ui-crop/ui-crop.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UiInputComponent } from './ui-input/ui-input.component';
import { UiEmailRoleComponent } from './ui-email-role/ui-email-role.component';
import { UiProgressButtonComponent } from './ui-progress-button/ui-progress-button.component';
import { UiPreloaderComponent } from './ui-preloader/ui-preloader.component';
import { LetDirective, PushPipe } from '@ngrx/component';
import { UiAvatarComponent } from './ui-avatar/ui-avatar.component';
import { PipesModule } from '../../pipes/pipes.module';
import { UiUploadComponent } from './ui-upload/ui-upload.component';
import { DirectivesModule } from '../directives/directives.module';
import { UiSomethingWentWrongComponent } from './ui-something-went-wrong/ui-something-went-wrong.component';
import { UiMenuLevel2Component } from './ui-menu-level2/ui-menu-level2.component';
import { RouterModule } from '@angular/router';
import { UiObjectCardComponent } from './ui-object-card/ui-object-card.component';
import { UiToggleComponent } from './ui-toggle/ui-toggle.component';
import { UiChipSelectComponent } from './ui-chip-select/ui-chip-select.component';
import { UiColorSelectComponent } from './ui-color-select/ui-color-select.component';
import { UiSearchComponent } from './ui-search/ui-search.component';
import { UiZoneSelectorComponent } from './ui-zone-selector/ui-zone-selector.component';
import { UiCameraSnapshotComponent } from './ui-camera-snapshot/ui-camera-snapshot.component';
import { UiSettingsDayScheduleComponent } from './ui-settings-day-schedule/ui-settings-day-schedule.component';
import { UiFilterChipComponent } from './ui-filter-chip/ui-filter-chip.component';
import { UiTitlePopupComponent } from './ui-title-popup/ui-title-popup.component';
import { MatRadioModule } from '@angular/material/radio';
import { UiSnapshotComponent } from './ui-snapshot/ui-snapshot.component';
import { UiTimePeriodPickerComponent } from './ui-time-period-picker/ui-time-period-picker.component';
import { QualitySelectorComponent } from './ui-quality-selector/quality-selector.component';
import { UiSelectorComponent } from './ui-selector/ui-selector.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UiTruncatedHtmlComponent } from './ui-truncated-html/ui-truncated-html.component';
import { UiChipGroupComponent } from './ui-chip-group/ui-chip-group.component';
import { UiUserSettingsDateComponent } from './ui-user-settings-date/ui-user-settings-date.component';
import { UiTerminalComponent } from './ui-terminal/ui-terminal.component';
import { UiPtzComponent } from './ui-ptz/ui-ptz.component';
import { UiZoneSelectorDialogComponent } from './ui-zone-selector/ui-zone-selector-dialog/ui-zone-selector-dialog.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UiCalendarInlineComponent } from './ui-calendar-inline/ui-calendar-inline.component';
import { UiRelativeTimeRangePickerComponent } from './ui-relative-time-range-picker/ui-relative-time-range-picker.component';
import { UiWeekDayPickerComponent } from './ui-week-day-picker/ui-week-day-picker.component';
import { UiCalendarComponent } from './ui-calendar/ui-calendar.component';
import { UiHowToDrawComponent } from './ui-how-to-draw/ui-how-to-draw.component';
import { UiAreaSelectorComponent } from './ui-area-selector/ui-area-selector.component';
import { UiNotificationActionComponent } from './ui-notification-action/ui-notification-action.component';
import { UiVideoPlayerComponent } from './ui-video-player/ui-video-player.component';
import { UiCommentFormComponent } from './ui-comment-form/ui-comment-form.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { UiLineCrossingComponent } from './ui-line-crossing/ui-line-crossing.component';
import { UiLineCrossingDialogComponent } from './ui-line-crossing/ui-line-crossing-dialog/ui-line-crossing-dialog.component';
import { UiTrafficControlComponent } from './ui-traffic-control/ui-traffic-control.component';
import { UiTrafficControlDialogComponent } from './ui-traffic-control/ui-line-crossing-dialog/ui-traffic-control-dialog.component';
import { UiEventSentenceComponent } from './ui-event-sentence/ui-event-sentence.component';
import { UiThumbnailImageComponent } from './ui-thumbnail-image/ui-thumbnail-image.component';
import { UiNoDataComponent } from './ui-no-data/ui-no-data.component';
import { UiHlsVideoPlayerComponent } from './ui-hls-video-player/ui-hls-video-player.component';
import { UiRangeSliderComponent } from './ui-range-slider/ui-range-slider.component';
import { UiPeriodComponent } from './ui-period/ui-period.component';
import { UiSnapshotV2Component } from './ui-snapshot-v2/ui-snapshot-v2.component';
import { UiPersonCardComponent } from './ui-person-card/ui-person-card.component';
import { UiPersonIconComponent } from './ui-person-icon/ui-person-icon.component';
import { UiPersonDialogComponent } from './ui-person-dialog/ui-person-dialog.component';
import { UiPersonSelectorComponent } from './ui-person-selector/ui-person-selector.component';
import { UiHeaderComponent } from './ui-header/ui-header.component';
import { UiCopyClipboardComponent } from './ui-copy-clipboard/ui-copy-clipboard.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { UiTableHeaderOrderComponent } from './ui-table-header-order/ui-table-header-order.component';
import { UiPhoneInputComponent } from './ui-phone-input/ui-phone-input.component';
import { UiDoorIconComponent } from './ui-door-icon/ui-door-icon.component';
import { UiDoorDialogComponent } from './ui-door-dialog/ui-door-dialog.component';
import { UiDoorSelectorComponent } from './ui-door-selector/ui-door-selector.component';
import { UiUserSelectorComponent } from './ui-user-selector/ui-user-selector.component';
import { UiEventTypesComponent } from './ui-event-types/ui-event-types.component';
import { UiMotionComponent } from './ui-motion/ui-motion.component';
import { UiThumbnailPreviewComponent } from './ui-thumbnail-preview/ui-thumbnail-preview.component';
import { UiScheduleComponent } from './ui-schedule/ui-schedule.component';
import { UiScheduleDialogComponent } from './ui-schedule/ui-schedule-dialog/ui-schedule-dialog.component';
import { UiVehicleDialogComponent } from './ui-vehicle-dialog/ui-vehicle-dialog.component';
import { UiVehicleIconComponent } from './ui-vehicle-icon/ui-vehicle-icon.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxSliderModule } from 'ngx-slider-v2';
import { MatDialogModule } from '@angular/material/dialog';
import { IntlTelInputNgModule } from 'intl-tel-input-ng';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UiOrgGroupDialogComponent } from './ui-org-group-dialog/ui-org-group-dialog.component';
import { UiOrgGroupMemberSelectorComponent } from './ui-org-group-member-selector/ui-org-group-member-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSliderModule } from '@angular/material/slider';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UiCounterComponent } from './ui-counter/ui-counter.component';
import { UiImgRatioComponent } from './ui-img-ratio/ui-img-ratio.component';
import { UiTrackerClassSelectorComponent } from './ui-tracker-class-selector/ui-tracker-class-selector.component';
import { UiWidgetComponent } from './ui-widget/ui-widget.component';
import { UiHeatmapComponent } from './ui-heatmap/ui-heatmap.component';
import { UiAlertOptionSelectorComponent } from './ui-alert-option-selector/ui-alert-option-selector.component';
import {
  UiCalendarTimeHeaderComponent,
} from './ui-calendar/components/ui-calendar-time-header/ui-calendar-time-header.component';
import { UiOptionToggleComponent } from './ui-option-toggle/ui-option-toggle.component';
import { UiUnusualEventComponent } from './ui-unusual-event/ui-unusual-event.component';
import { UiVehicleSelectorComponent } from './ui-vehicle-selector/ui-vehicle-selector.component';
import { UiImageUploadDialogComponent } from './ui-image-upload-dialog/ui-image-upload-dialog.component';
import { QualitySelectorV2Component } from './ui-quality-selector-v2/quality-selector-v2.component';

const sharedComponents = [
  UiDatetimeRangePickerComponent,
  UiPrefixInputComponent,
  UiButtonComponent,
  UiDateTimeHeaderComponent,
  UiConfirmComponent,
  UiAlertComponent,
  UiCustomToastComponent,
  UiZoneSelectorComponent,
  UiCropComponent,
  UiInputComponent,
  UiEmailRoleComponent,
  UiProgressButtonComponent,
  UiPreloaderComponent,
  UiAvatarComponent,
  UiUploadComponent,
  UiSomethingWentWrongComponent,
  UiMenuLevel2Component,
  UiObjectCardComponent,
  UiToggleComponent,
  UiChipSelectComponent,
  UiColorSelectComponent,
  UiSearchComponent,
  UiCameraSnapshotComponent,
  UiSettingsDayScheduleComponent,
  QualitySelectorComponent,
  UiSelectorComponent,
  UiTruncatedHtmlComponent,
  UiLineCrossingComponent,
  UiLineCrossingDialogComponent,
  UiTrafficControlComponent,
  UiTrafficControlDialogComponent,
  UiImgRatioComponent,
  UiTrackerClassSelectorComponent,
  UiWidgetComponent,
  UiHeatmapComponent,
  UiAlertOptionSelectorComponent,
  UiRangeSliderComponent,
  UiOptionToggleComponent,
  UiImageUploadDialogComponent,
  QualitySelectorV2Component,
];


@NgModule({
  declarations: [
    sharedComponents,
    UiFilterChipComponent,
    UiTitlePopupComponent,
    UiSnapshotComponent,
    UiTimePeriodPickerComponent,
    UiChipGroupComponent,
    UiUserSettingsDateComponent,
    UiTerminalComponent,
    UiPtzComponent,
    UiZoneSelectorDialogComponent,
    UiCalendarInlineComponent,
    UiRelativeTimeRangePickerComponent,
    UiWeekDayPickerComponent,
    UiCalendarComponent,
    UiDatetimeRangePickerComponent,
    UiHowToDrawComponent,
    UiAreaSelectorComponent,
    UiNotificationActionComponent,
    UiVideoPlayerComponent,
    UiCommentFormComponent,
    UiLineCrossingComponent,
    UiLineCrossingDialogComponent,
    UiEventSentenceComponent,
    UiThumbnailImageComponent,
    UiNoDataComponent,
    UiHlsVideoPlayerComponent,
    UiPeriodComponent,
    UiPersonCardComponent,
    UiPersonIconComponent,
    UiPersonDialogComponent,
    UiSnapshotV2Component,
    UiPersonSelectorComponent,
    UiHeaderComponent,
    UiCopyClipboardComponent,
    UiTableHeaderOrderComponent,
    UiPhoneInputComponent,
    UiDoorIconComponent,
    UiDoorDialogComponent,
    UiOrgGroupDialogComponent,
    UiDoorSelectorComponent,
    UiUserSelectorComponent,
    UiEventTypesComponent,
    UiMotionComponent,
    UiThumbnailPreviewComponent,
    UiScheduleComponent,
    UiScheduleDialogComponent,
    UiVehicleDialogComponent,
    UiVehicleIconComponent,
    UiOrgGroupMemberSelectorComponent,
    UiCounterComponent,
    UiCalendarTimeHeaderComponent,
    UiUnusualEventComponent,
    UiVehicleSelectorComponent,
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    FlexModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatIconModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgScrollbarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    ImageCropperModule,
    LetDirective, PushPipe,
    PipesModule,
    DirectivesModule,
    RouterModule,
    MatChipsModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMenuModule,
    ScrollingModule,
    // NouisliderModule,
    LazyLoadImageModule,
    DragDropModule,
    MatSliderModule,
    // NgxSliderModule,
    ClipboardModule,
    MatAutocompleteModule,
    NgxSliderModule,
    IntlTelInputNgModule,
    // Ng2TelInputModule,
  ],
  exports: [
    sharedComponents,
    UiFilterChipComponent,
    UiSnapshotComponent,
    UiTimePeriodPickerComponent,
    QualitySelectorComponent,
    UiChipGroupComponent,
    UiUserSettingsDateComponent,
    UiTerminalComponent,
    UiPtzComponent,
    UiCalendarInlineComponent,
    UiRelativeTimeRangePickerComponent,
    UiWeekDayPickerComponent,
    UiDatetimeRangePickerComponent,
    UiCalendarComponent,
    UiHowToDrawComponent,
    UiAreaSelectorComponent,
    UiNotificationActionComponent,
    UiVideoPlayerComponent,
    UiCommentFormComponent,
    UiEventSentenceComponent,
    UiThumbnailImageComponent,
    UiNoDataComponent,
    UiPeriodComponent,
    UiPersonCardComponent,
    UiPersonIconComponent,
    UiSnapshotV2Component,
    UiPersonSelectorComponent,
    UiHeaderComponent,
    UiCopyClipboardComponent,
    UiTableHeaderOrderComponent,
    UiPhoneInputComponent,
    UiDoorIconComponent,
    UiVehicleIconComponent,
    UiUserSelectorComponent,
    UiEventTypesComponent,
    UiMotionComponent,
    UiThumbnailPreviewComponent,
    UiScheduleComponent,
    UiScheduleComponent,
    UiOrgGroupMemberSelectorComponent,
    UiCounterComponent,
    UiImgRatioComponent,
    UiUnusualEventComponent,
    UiVehicleSelectorComponent,
  ],
})
export class UiKitModule {
}
