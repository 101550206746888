import { LiveStreamModels } from '@models/live-stream.model';

export const hlsDefaults: LiveStreamModels.HLS = {

  hlsFragmentDuration: 4000,
  hlsMaxLiveFragments: 3,
  hlsMaxStoredFragments: 6,

  // MQ
  hlsMQScaledFragmentDuration: 2000,
  hlsMQMaxLiveFragments: 3,
  hlsMQMaxStoredFragments: 6,
  hlsMQFromMainstream: false,
  hlsMQScaledWidth: 960,
  hlsMQScaledHeight: 540,
  hlsMQScaledBitrateBps: 800000,
  hlsMQScaledPeakBitrateBps: 1200000,
  hlsMQScaledIFrameInterval: 30,
  hlsMQScaledIFrameIntervalFromFPS: false,
  hlsMQScaledRateControlType: LiveStreamModels.RateControlType.CBR,
  hlsMQScaledPresetLevel: LiveStreamModels.RateControlPresetLevel.UltraFast,

  // SQ
  hlsSQScaledFragmentDuration: 1000,
  hlsSQMaxLiveFragments: 3,
  hlsSQMaxStoredFragments: 6,
  hlsSQScaledWidth: 426,
  hlsSQScaledHeight: 240,
  hlsSQScaledBitrateBps: 500000,
  hlsSQScaledPeakBitrateBps: 700000,
  hlsSQScaledIFrameInterval: 15,
  hlsSQScaledIFrameIntervalFromFPS: false,
  hlsSQScaledRateControlType: LiveStreamModels.RateControlType.CBR,
  hlsSQScaledPresetLevel: LiveStreamModels.RateControlPresetLevel.UltraFast,
};
