import { createAction, props } from '@ngrx/store';
import { Location } from 'src/app/locations/location.model';

export const resetToInitialState = createAction('[TERMINAL] Reset to Initial state');

export const setEdgeId = createAction('[TERMINAL] Set edge Id', props<{ edgeId: string }>());

export const setLocationId = createAction('[TERMINAL] Set Location Id', props<{ locationId: string }>());

export const setContext = createAction('[TERMINAL] Set Context', props<{context: Location.EdgeLookupParams}>());

/**
 * If location id set from terminal directly, edgeId should be return to initial.
 */
export const setLocationIdTerminal = createAction('[TERMINAL] Set Location Id Terminal', props<{ locationId: string }>());

export const setEdgeIdTerminal = createAction('[TERMINAL] Set edge Id Terminal', props<{ edgeId: string }>());

export const setCommandFail = createAction('[TERMINAL] Set Command Fail', props<{ msg: string }>());

export const setCommandFailError = createAction('[TERMINAL] Set Command Fail Error', props<{ msg: string }>());

export const manage = createAction(
  '[TERMINAL] manage',
  props<{ action: number; params: { [key: string]: string | number }; msTimeout?: number }>(),
);

export const dispacth = createAction(
  '[TERMINAL] dispacth',
  props<{ action: number; params: { [key: string]: string | number }; msTimeout?: number }>(),
);

export const cancelDispatchRequest = createAction('[TERMINAL] Cancel Dispatch Request');

export const cancelManageRequest = createAction('[TERMINAL] Cancel Manage Request');
