import { createAction, props } from '@ngrx/store';
import { Location } from '../../../locations/location.model';
import { Edge } from '../../../edge/edge.model';
import { EdgeCamera } from '../../../cameras/camera.model';

enum LocationActionTypes {
  GetLocations = '[Locations API] GetLocations',
  GetLocationsSuccess = '[Locations API] GetLocations Success',
  GetLocationsFail = '[Locations API] GetLocations Fail',

  SetLocationsLookup = '[Locations API] SetLocationsLookup',

  CreateLocationBackendCall = '[Locations API] CreateLocationBackendCall',
  CreateLocationNoBackendCall = '[Locations API] CreateLocationNoBackendCall',
  CreateLocationSuccess = '[Locations API] CreateLocationSuccess',
  CreateLocationFail = '[Locations API] CreateLocationFail',

  DeleteCameraNoBackendCall = '[Locations API] DeleteCameraNoBackendCall',
  DeleteCameraSuccess = '[Locations API] DeleteCameraSuccess',
  DeleteCameraFail = '[Locations API] DeleteCameraFail',

  DeleteLocationNoBackendCall = '[Locations API] DeleteLocationNoBackendCall',
  DeleteLocation = '[Locations API] DeleteLocation',
  DeleteLocationSuccess = '[Locations API] DeleteLocationSuccess',
  DeleteLocationFail = '[Locations API] DeleteLocationFail',

  UploadEdgeConfig = '[Locations API] UploadEdgeConfig',
  UploadEdgeConfigJson = '[Locations API] UploadEdgeConfigJson',
  SendEdgeConfig = '[Locations API] SendEdgeConfig',
  SendEdgeConfigJson = '[Locations API] SendEdgeConfigJson',
  StartSendEdgeConfigSubscribeToSessionStatus = '[Locations API] StartSendEdgeConfigSubscribeToSessionStatus',
  SendEdgeConfigSubscribeToSessionStatus = '[Locations API] SendEdgeConfigSubscribeToSessionStatus',
  SendEdgeConfigSessionStatusChanged = '[Locations API] SendEdgeConfigSessionStatusChanged',
  SetEdgeConfigUploaded = '[Locations API] SetEdgeConfigUploaded',

  ResetEdgeConfig = '[Locations API] ResetEdgeConfig',
  GetEdgeConfig = '[Locations API] GetEdgeConfig',
  GetEdgeConfigSubscribeToSessionStatus = '[Locations API] GetEdgeConfigSubscribeToSessionStatus',
  GetEdgeConfigSessionStatusChanged = '[Locations API] GetEdgeConfigSessionStatusChanged',
  SetEdgeConfig = '[Locations API] SetEdgeConfig',

  ResetEdgeDocument = '[Locations API] ResetEdgeDocument',
  GetEdgeDocument = '[Locations API] GetEdgeDocument',
  GetEdgeDocumentSubscribeToSessionStatus = '[Locations API] GetEdgeDocumentSubscribeToSessionStatus',
  GetEdgeDocumentSessionStatusChanged = '[Locations API] GetEdgeDocumentSessionStatusChanged',
  SetEdgeDocument = '[Locations API] SetEdgeDocument',

  ResetEdgeInfo = '[Locations API] ResetEdgeInfo',
  GetEdgeInfo = '[Locations API] GetEdgeInfo',
  GetEdgeInfoSubscribeToSessionStatus = '[Locations API] GetEdgeInfotSubscribeToSessionStatus',
  GetEdgeInfoSessionStatusChanged = '[Locations API] GetEdgeInfoSessionStatusChanged',
  SetEdgeInfo = '[Locations API] SetEdgeInfo',

  ApproveEdgeConfig = '[Locations API] ApproveEdgeConfig',
  SendApproveEdgeConfig = '[Locations API] SendApproveEdgeConfig',
  StartApproveEdgeConfigSubscribeToSessionStatus = '[Locations API] StartApproveEdgeConfigSubscribeToSessionStatus',
  ApproveEdgeConfigSubscribeToSessionStatus = '[Locations API] ApproveEdgeConfigSubscribeToSessionStatus',
  ApproveEdgeConfigSessionStatusChanged = '[Locations API] ApproveEdgeConfigSessionStatusChanged',
  ApproveEdgeConfigSessionStatusChangedSuccess = '[Locations API] ApproveEdgeConfigSessionStatusChangedSuccess',

  UpdateLocationNoBackendCall = '[Locations API] UpdateLocationNoBackendCall',
  ExpandLocation = '[Locations API] Expand Location',
  CollapseLocation = '[Locations API] Collapse Location',
  ExpandLocationEdge = '[Locations API] Expand Location core',
  CollapseLocationEdge = '[Locations API] Collapse Location core',
  ExpandAll = '[Locations API] Expand All',
  CollapseAll = '[Locations API] Collapse All',

}

export const GetLocations = createAction(LocationActionTypes.GetLocations);

export const GetLocationsSuccess = createAction(
  LocationActionTypes.GetLocationsSuccess,
  props<{ payload: Location.GetLocationsResponse }>(),
);

export const GetLocationsFail = createAction(LocationActionTypes.GetLocationsFail, props<{ message?: string }>());

export const CreateLocationBackendCall = createAction(
  LocationActionTypes.CreateLocationBackendCall,
  props<{ request: Location.LocationCreateRequest }>(),
);

export const CreateLocationNoBackendCall = createAction(
  LocationActionTypes.CreateLocationNoBackendCall,
  props<{ request: Location.LocationCreateRequest }>(),
);

export const CreateLocationSuccess = createAction(LocationActionTypes.CreateLocationSuccess, props<{ response: Location.LocationItem }>());

export const CreateLocationFail = createAction(LocationActionTypes.CreateLocationFail, props<{ message?: string }>());

export const DeleteCameraNoBackendCall = createAction(
  LocationActionTypes.DeleteCameraNoBackendCall,
  props<{ request: { locationId: string; edgeId: string; cameraId: string } }>(),
);

export const DeleteCameraSuccess = createAction(
  LocationActionTypes.DeleteCameraSuccess,
  props<{
    response: {
      location: Location.LocationItem;
      locationId: string;
      edgeId: string;
      cameraId: string;
    };
  }>(),
);

export const DeleteCameraFail = createAction(LocationActionTypes.DeleteCameraFail, props<{ message?: string }>());

export const DeleteLocation = createAction(LocationActionTypes.DeleteLocation, props<{ request: { locationId: string } }>());

export const DeleteLocationNoBackendCall = createAction(
  LocationActionTypes.DeleteLocationNoBackendCall,
  props<{ request: { locationId: string } }>(),
);

export const DeleteLocationSuccess = createAction(
  LocationActionTypes.DeleteLocationSuccess,
  props<{ response: Location.LocationDeleteResponse }>(),
);

export const DeleteLocationFail = createAction(LocationActionTypes.DeleteLocationFail, props<{ message?: string }>());

/**
 * edge Config SEND/UPLOAD
 */

export const UploadEdgeConfig = createAction(
  LocationActionTypes.UploadEdgeConfig,
  props<{ file: any; edgeId: string; locationId: string }>(),
);

export const UploadEdgeConfigJson = createAction(
  LocationActionTypes.UploadEdgeConfigJson,
  props<{ config: string; edgeId: string; locationId: string }>(),
);

export const SendEdgeConfig = createAction(LocationActionTypes.SendEdgeConfig, props<{ file: any; edgeId: string; locationId: string }>());

export const SendEdgeConfigJson = createAction(
  LocationActionTypes.SendEdgeConfigJson,
  props<{ config: string; edgeId: string; locationId: string }>(),
);

export const StartSendEdgeConfigSubscribeToSessionStatus = createAction(
  LocationActionTypes.StartSendEdgeConfigSubscribeToSessionStatus,
  props<{ token: string }>(),
);

export const SendEdgeConfigSubscribeToSessionStatus = createAction(
  LocationActionTypes.SendEdgeConfigSubscribeToSessionStatus,
  props<{ token: string }>(),
);

export const SendEdgeConfigSessionStatusChanged = createAction(
  LocationActionTypes.SendEdgeConfigSessionStatusChanged,
  props<{ token: string }>(),
);

export const SetEdgeConfigUploaded = createAction(LocationActionTypes.SetEdgeConfigUploaded, props<{ uploaded: boolean }>());

/**
 * edge config GET
 */

export const ResetEdgeConfig = createAction(LocationActionTypes.ResetEdgeConfig);

export const GetEdgeConfig = createAction(LocationActionTypes.GetEdgeConfig, props<{ edgeId: string; locationId: string }>());

export const GetEdgeConfigSubscribeToSessionStatus = createAction(
  LocationActionTypes.GetEdgeConfigSubscribeToSessionStatus,
  props<{ token: string }>(),
);

export const GetEdgeConfigSessionStatusChanged = createAction(
  LocationActionTypes.GetEdgeConfigSessionStatusChanged,
  props<{ token: string }>(),
);

export const SetEdgeConfig = createAction(LocationActionTypes.SetEdgeConfig, props<{ edgeConfigJson: any }>());

/**
 * Approve edge config
 */
export const ApproveEdgeConfig = createAction(LocationActionTypes.ApproveEdgeConfig, props<{ edgeId: string; locationId: string }>());

export const SendApproveEdgeConfig = createAction(
  LocationActionTypes.SendApproveEdgeConfig,
  props<{ edgeId: string; locationId: string }>(),
);

export const StartApproveEdgeConfigSubscribeToSessionStatus = createAction(
  LocationActionTypes.StartApproveEdgeConfigSubscribeToSessionStatus,
  props<{ token: string }>(),
);

export const ApproveEdgeConfigSubscribeToSessionStatus = createAction(
  LocationActionTypes.ApproveEdgeConfigSubscribeToSessionStatus,
  props<{ token: string }>(),
);

export const ApproveEdgeConfigSessionStatusChanged = createAction(
  LocationActionTypes.ApproveEdgeConfigSessionStatusChanged,
  props<{ token: string }>(),
);

export const ApproveEdgeConfigSessionStatusChangedSuccess = createAction(LocationActionTypes.ApproveEdgeConfigSessionStatusChangedSuccess);

/**
 * Location Lookup
 */
export const SetLocationsLookup = createAction(
  LocationActionTypes.SetLocationsLookup,
  props<{ lookup: { [key: string]: Location.LocationItem } }>(),
);

/**
 * edge document GET
 */

export const ResetEdgeDocument = createAction(LocationActionTypes.ResetEdgeDocument);

export const GetEdgeDocument = createAction(LocationActionTypes.GetEdgeDocument, props<{ edgeId: string; locationId: string }>());

export const GetEdgeDocumentSubscribeToSessionStatus = createAction(
  LocationActionTypes.GetEdgeDocumentSubscribeToSessionStatus,
  props<{ token: string }>(),
);

export const GetEdgeDocumentSessionStatusChanged = createAction(
  LocationActionTypes.GetEdgeDocumentSessionStatusChanged,
  props<{ token: string }>(),
);

export const SetEdgeDocument = createAction(LocationActionTypes.SetEdgeDocument, props<{ edgeDocument: any }>());

/**
 * edge info GET
 */

export const ResetEdgeInfo = createAction(LocationActionTypes.ResetEdgeInfo);

export const GetEdgeInfo = createAction(LocationActionTypes.GetEdgeInfo, props<{ edgeId: string; locationId: string }>());

export const GetEdgeInfoSubscribeToSessionStatus = createAction(
  LocationActionTypes.GetEdgeInfoSubscribeToSessionStatus,
  props<{ token: string }>(),
);

export const GetEdgeInfoSessionStatusChanged = createAction(
  LocationActionTypes.GetEdgeInfoSessionStatusChanged,
  props<{ token: string }>(),
);

export const SetEdgeInfo = createAction(LocationActionTypes.SetEdgeInfo, props<{ edgeInfo: any }>());

export const filterLocationBy = createAction(
  'Filter Location By',
  props<{ locationIds: string[]; edgeIds: string[]; cameraIds: string[]; statuses: string[]; query: string }>(),
);

export const filterLocationByField = createAction('Filter Location By Field', props<{ field: string; value: string[] }>());
export const filterCamerasByStatus = createAction('Filter Location By Status', props<{ cameraIds: string[] }>());
export const removeFilterLocationByField = createAction('Remove Filter Location By Field', props<{ field: string; value: string }>());
export const searchLocationByQuery = createAction('Search Location By Query', props<{ query: string }>());

export const UpdateLocationNoBackendCall = createAction(
  LocationActionTypes.UpdateLocationNoBackendCall,
  props<{ location: Location.LocationItem }>(),
);

export const ExpandLocation = createAction(
  LocationActionTypes.ExpandLocation,
  props<{ locationId: string; }>(),
);

export const CollapseLocation = createAction(
  LocationActionTypes.CollapseLocation,
  props<{ locationId: string; }>(),
);

export const ExpandLocationEdge = createAction(
  LocationActionTypes.ExpandLocationEdge,
  props<{ locationId: string; edgeId: string }>(),
);

export const CollapseLocationEdge = createAction(
  LocationActionTypes.CollapseLocationEdge,
  props<{ locationId: string; edgeId: string }>(),
);

export const ExpandAll = createAction(
  LocationActionTypes.ExpandAll,
);

export const CollapseAll = createAction(
  LocationActionTypes.CollapseAll,
);

export const removeCameraById = createAction(
  'Remove Camera By Id',
  props<{ locationId: string; edgeId: string, cameraId: string }>(),
);

export const updateEdgeNoBackendCall = createAction(
  'Update Edge No Backend Call',
  props<{ locationId: string, edge: Partial<Edge.EdgeDocument>; }>(),
);

export const updateCameraNoBackendCall = createAction(
  'Update Camera No Backend Call',
  props<{ locationId: string, edgeId: string; camera: Partial<EdgeCamera.CameraDocument> }>(),
);

