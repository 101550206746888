import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { CameraRowComponent } from '../camera-row/camera-row.component';
import { Dictionary } from '@ngrx/entity/src/models';
import { SelectedCamera } from '@models/alert-events.model';
import { Edge } from '../../../../edge/edge.model';
import EdgeItem = Edge.EdgeItem;
import { Location } from '../../../../locations/location.model';

@Component({
  selector: 'camera-selector-edge-row',
  standalone: true,
  imports: [
    MatCheckbox,
    AsyncPipe,
    NgIf,
    CameraRowComponent,
    NgForOf,
    JsonPipe,
  ],
  templateUrl: './edge-row.component.html',
  styleUrls: ['./edge-row.component.scss', './../../styles/index.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EdgeRowComponent implements OnChanges {
  @Input() edge: EdgeItem;
  @Input() selectedCameras: Dictionary<SelectedCamera>;
  @Input() isCollapsed: boolean = false;

  @Output() onCameraSelected: EventEmitter<SelectedCamera> = new EventEmitter<SelectedCamera>();
  @Output() onEdgeSelected: EventEmitter<{ edgeId: string; checked: boolean }> = new EventEmitter<{ edgeId: string; checked: boolean }>();
  public collapsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['isCollapsed']) {
      this.collapsed$.next(changes['isCollapsed'].currentValue);
    }
  }

  public toggleEdge() {
    const currentValue = this.collapsed$.getValue();
    this.collapsed$.next(!currentValue);
  }

  public get cameras() {
    return Object.values(this.edge.cameras ?? {});
  }

  public selectCamera(camera: SelectedCamera) {
    this.onCameraSelected.emit(camera);
  }

  public selectEdge(ev: MatCheckboxChange) {
    this.onEdgeSelected.emit({ edgeId: this.edge.edgeId, checked: ev.checked });
  }

  public get selected() {
    const selectedCameras = Object.keys(this.selectedCameras);
    return this.cameras
      .every(camera => selectedCameras.includes(camera.edgeOnly.cameraId));
  }

  public trackBy(index: number, camera: Location.LocationCameraItem) {
    return camera.edgeOnly.cameraId;
  }
}
