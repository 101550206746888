import { createReducer, on } from '@ngrx/store';
import * as WallActions from '@states/wall/wall.actions';
import * as _ from 'lodash';
import { EdgeCamera } from '../../../cameras/camera.model';
import { WallAlert, WallAlertMonitoringFilters, WallFilters, WallModel, WallModelV2 } from '@models/wall.model';
import { defaultAlertTileConfig, defaultCameraTileConfig, defaultWall, initialAlertsMonitoringFilters } from '@consts/wall.const';
import { AlertEntry } from '../../../development/alerts.service';
import { WallAlertListPosition, WallLayoutType } from '@enums/wall.enum';
import { CameraAlertSelectorResult } from '../../../modals/camera-alert-selector-modal/camera-alert-selector-modal.component';
import { AppleTvModels } from '@models/apple-tv.models';
import { SocketModels } from '../../../socket/socket.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { SelectedCamera } from '@models/alert-events.model';
import WallLayout = WallModelV2.WallLayout;
import WallSet = WallModelV2.WallSet;
import wallLayoutCameraCountV2 = WallModelV2.wallLayoutCameraCountV2;
import WallTile = WallModelV2.WallTile;

export interface WallCameraLocalStatus {
  isLocal?: boolean;
  forceCloud?: boolean;
  relay?: boolean;
}

export interface WallState {
  selectedWall: WallModel;
  selectedSetIndex: number;
  walls: WallModel[];
  isExpanded: boolean;
  localStatus: WallCameraLocalStatus[];
  alertsMonitoringFilters: WallAlertMonitoringFilters;
  alerts: { [key: string]: WallAlert };
  lastWallTimestamp: number;
  isLastPage: boolean;
  limit: number;
  isFirstLoad: boolean;
  isLoading: boolean;
  tilesAlerts: {
    [tile: number]: AlertEntry
  };
  isValid: boolean;
  isDebugMode: boolean;
  nextSelectedSetIndex: number;
  filters: WallFilters;
  isMuted: boolean;
  appleTvs: AppleTvModels.AppleTvDocument[];
}

const initialState: WallState = {
  selectedWall: null,
  selectedSetIndex: 0,
  walls: null,
  isExpanded: false,
  localStatus: [],
  alertsMonitoringFilters: initialAlertsMonitoringFilters,
  alerts: {},
  lastWallTimestamp: null,
  isLastPage: false,
  limit: 50,
  isFirstLoad: true,
  isLoading: true,
  tilesAlerts: {},
  isValid: false,
  isDebugMode: false,
  nextSelectedSetIndex: 1,
  filters: {
    query: null,
    isPrivate: [],
    selectedCameras: {},
  },
  isMuted: false,
  appleTvs: [],
};

export const wallStateReducer = createReducer(
  initialState,
  on(WallActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(WallActions.selectLayout, (state, { layoutIndex }) => {
    return {
      ...state,
      layoutIndex,
      selectedWall: {
        ...state.selectedWall,
        sets: selectLayout(layoutIndex, state.selectedWall.sets, state.selectedSetIndex),
      },
    };
  }),
  on(WallActions.setSelectedWall, (state, { selectedWall }) => {
    return {
      ...state,
      selectedWall,
      selectedSetIndex: initialState.selectedSetIndex,
      nextSelectedSetIndex: initialState.nextSelectedSetIndex,
    };
  }),
  on(WallActions.initLocalStatusArray, (state, { size }) => {
    const localStatus = new Array<WallCameraLocalStatus>(size).fill({
      isLocal: false,
      forceCloud: false,
      relay: false,
    });
    return {
      ...state,
      localStatus,
    };
  }),
  on(WallActions.setCameraLocalStatus, (state, { index, status }) => {
    const localStatus = [...state.localStatus];
    localStatus[index] = status;
    return {
      ...state,
      localStatus,
    };
  }),
  on(WallActions.addSet, (state, { name }) => {
    const newSets = addSet(state.selectedWall.sets, name);
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        sets: newSets,
      },
      selectedSetIndex: 0,
    };
  }),
  on(WallActions.setSelectedSetIndex, (state, { selectedSetIndex }) => {
    return {
      ...state,
      selectedSetIndex,
      nextSelectedSetIndex: state.selectedWall.sets.length - 1 > selectedSetIndex ? selectedSetIndex + 1 : 0,
    };
  }),
  on(WallActions.setPrivate, (state, { isPrivate }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        isPrivate,
      },
    };
  }),
  on(WallActions.setWallName, (state, { name }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        name,
      },
    };
  }),
  on(WallActions.setWalls, (state, { walls }) => {
    const contactedWalls = state.walls ?? [];
    return {
      ...state,
      walls: contactedWalls.concat(walls),
      isLastPage: walls.length < initialState.limit,
      isFirstLoad: false,
    };
  }),
  on(WallActions.setIsExpanded, state => {
    return {
      ...state,
      isExpanded: !state.isExpanded,
    };
  }),
  on(WallActions.dropCamera, (state, { prevDataIndex, nextDataIndex }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        sets: dragAndDropCameraInSet(state.selectedWall.sets, state.selectedSetIndex, prevDataIndex, nextDataIndex),
      },
    };
  }),
  on(WallActions.deleteCamera, (state, { cameraIndex }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        sets: deleteCameraByIndexFromSet(state.selectedWall.sets, state.selectedSetIndex, cameraIndex),
      },
    };
  }),
  on(WallActions.removeSet, (state, { setIndex }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        sets: removeSetByIndex(state.selectedWall.sets, setIndex),
      },
      selectedSetIndex: 0,
    };
  }),
  on(WallActions.renameSet, (state, { name, setIndex }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        sets: renameSetByIndex(state.selectedWall.sets, setIndex, name),
      },
    };
  }),
  on(WallActions.getAlertsMonitoringSuccess, (state, { alerts, tilesAlerts }) => {
    return {
      ...state,
      alerts,
      tilesAlerts,
    };
  }),
  on(WallActions.getAlertsMonitoringTableSuccess, (state, { alerts }) => {
    return {
      ...state,
      alerts,
    };
  }),
  on(WallActions.getAlertsMonitoringTilesSuccess, (state, { tilesAlerts }) => {
    return {
      ...state,
      tilesAlerts,
    };
  }),
  on(WallActions.archiveAlertSuccess, (state, { eventId, alertId }) => {
    const alerts = _.cloneDeep(state.alerts);
    delete alerts[alertId];
    return {
      ...state,
      alerts,
    };
  }),
  on(WallActions.setAlertFilterFrequency, (state, { frequency }) => {
    return {
      ...state,
      alertsMonitoringFilters: {
        ...state.alertsMonitoringFilters,
        frequency,
      },
    };
  }),
  on(WallActions.archiveAlertCanceled, (state, { eventId, alertId }) => {
    return {
      ...state,
      alerts: {
        ...state.alerts,
        [alertId]: {
          ...state.alerts[alertId],
          archivedAt: null,
        },
      },
    };
  }),
  on(WallActions.nextPage, (state) => {
    return {
      ...state,
      lastWallTimestamp: state.walls ? state.walls[state.walls.length - 1].timestamp : null,
    };
  }),
  on(WallActions.setSearchFilters, (state, { value, field }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [field]: value,
      },
      isLastPage: initialState.isLastPage,
      lastWallTimestamp: initialState.lastWallTimestamp,
      walls: initialState.walls,
      isLoading: initialState.isLoading,
    };
  }),
  on(WallActions.setLoader, (state, { isLoading }) => {
    return {
      ...state,
      isLoading,
    };
  }),
  on(WallActions.setAlertListPositions, (state, { position }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        alertPosition: position,
      },
    };
  }),
  on(WallActions.setRotateDuration, (state, { duration }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        rotateDuration: duration,
      },
    };
  }),
  on(WallActions.selectAlertSuccess, (state, { tilesAlerts, alerts }) => {
    return {
      ...state,
      tilesAlerts,
      alerts,
    };
  }),
  on(WallActions.resetTiles, (state) => {
    return {
      ...state,
      tilesAlerts: initialState.tilesAlerts,
    };
  }),
  /**
   * Need to refresh is last page wallue, cause need to refresh list.
   */
  on(WallActions.refreshIsLastPage, (state) => {
    return {
      ...state,
      isLastPage: initialState.isLastPage,
    };
  }),
  on(WallActions.setValidation, (state, { isValid }) => {
    return {
      ...state,
      isValid,
    };
  }),
  on(WallActions.switchDebugMode, (state) => {
    return {
      ...state,
      isDebugMode: !state.isDebugMode,
    };
  }),
  on(WallActions.removeSelectedEvent, (state, { positionIndex, eventId, cameraId }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        sets: removeSelectedEvent(positionIndex, state.selectedWall.sets, state.selectedSetIndex, eventId, cameraId),
      },
    };
  }),
  on(WallActions.updatePosition, (state, { positionIndex, selectionResult }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        sets: updateSetPosition(positionIndex, state.selectedWall.sets, state.selectedSetIndex, selectionResult),
      },
    };
  }),
  on(WallActions.resetAlerts, (state) => {
    return {
      ...state,
      alerts: initialState.alerts,
    };
  }),
  on(WallActions.clearTileInThreshold, (state, { tile }) => {
    const tilesAlerts = _.cloneDeep(state.tilesAlerts);
    delete tilesAlerts[tile];
    return {
      ...state,
      tilesAlerts,
    };
  }),
  on(WallActions.removeSelectedCameraEvent, (state, { positionIndex, cameraId }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        sets: removeSelectedCameraEvent(positionIndex, state.selectedWall.sets, state.selectedSetIndex, cameraId),
      },
    };
  }),
  on(WallActions.changeAlertOrdering, (state, { alertOrdering }) => {
    return {
      ...state,
      alerts: initialState.alerts,
      tilesAlerts: initialState.tilesAlerts,
      selectedWall: {
        ...state.selectedWall,
        alertOrdering,
        sets: resetSets(state.selectedWall.sets),
      },
    };
  }),
  on(WallActions.rmSearchFilters, (state, { field, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [field]: removeFilter(state.filters, value, field),
      },
      isLastPage: initialState.isLastPage,
      lastWallTimestamp: initialState.lastWallTimestamp,
      walls: initialState.walls,
      isLoading: initialState.isLoading,
    };
  }),
  on(WallActions.setWallLayoutType, (state, { layoutType }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        layoutType,
        alertPosition: layoutType === WallLayoutType.CAMERAS ? WallAlertListPosition.NONE : WallAlertListPosition.TOP,
        sets: resetSets(state.selectedWall.sets),
        alerts: defaultWall.alerts,
      },
    };
  }),
  on(WallActions.updateExternalAlerts, (state, { selectionResult }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        alerts: updateAlertsExternal(selectionResult),
      },
    };
  }),
  on(WallActions.setIsMuted, (state, { isMuted }) => {
    return {
      ...state,
      isMuted,
    };
  }),
  on(WallActions.refreshAlertsTilesSuccess, (state, { alerts }) => {
    return {
      ...state,
      alerts,
    };
  }),
  on(WallActions.getAppleTvSuccess, (state, { appleTvs }) => {
    return {
      ...state,
      appleTvs,
    };
  }),
  on(WallActions.updateAppleTvSetOne, (state, { configureWallResponse }) => {
    return {
      ...state,
      selectedWall: updateSelectedWallByAppleTv(state.selectedWall, configureWallResponse),
      walls: updateWallsByAppleTv(state.walls, configureWallResponse),
    }
      ;
  }),
);

const selectLayout = (layoutIndex: number, _sets: WallSet[], currentSetIndex: number) => {
  const sets = _.cloneDeep(_sets);
  const layoutCameras = wallLayoutCameraCountV2[layoutIndex];
  sets[currentSetIndex].layout = layoutIndex;
  const tiles = [];
  for(let i = 0; i < layoutCameras; i++) {
    if (!sets[currentSetIndex].tiles[i]) {
      tiles[i] = { alertTileConfig: defaultAlertTileConfig, cameraTileConfig: defaultCameraTileConfig, data: null };
    } else {
      tiles[i] = sets[currentSetIndex].tiles[i];
    }
  }
  sets[currentSetIndex].tiles = [...tiles];
  return sets;
};

const addSet = (_sets: WallModelV2.WallSet[], name: string) => {
  const sets = _.cloneDeep(_sets);
  if (sets.length < 5) {
    sets.push({
      name: name ?? `Set ${sets.length + 1}`,
      tiles: [],
      layout: WallLayout.GRID_9,
    });
  }
  return sets;
};

//todo make tests
const deleteCameraByIndexFromSet = (_sets: WallSet[], currentSetIndex: number, cameraIndex: number) => {
  const sets = _.cloneDeep(_sets);
  delete sets[currentSetIndex].tiles[cameraIndex].data;
  return sets;
};

//todo make tests
const dragAndDropCameraInSet = (_sets: WallSet[], currentSetIndex: number, prevDataIndex: number, nextDataIndex: number) => {
  const sets = _.cloneDeep(_sets);
  if (prevDataIndex != nextDataIndex) {
    const origPos = sets[currentSetIndex].tiles[prevDataIndex];
    sets[currentSetIndex].tiles[prevDataIndex] = sets[currentSetIndex].tiles[nextDataIndex];
    sets[currentSetIndex].tiles[nextDataIndex] = origPos;
    return sets;
  }
  return sets;
};

const removeSetByIndex = (_sets: WallSet[], setIndex: number) => {
  const sets = _.cloneDeep(_sets);
  if (sets.length > 1) {
    sets.splice(setIndex, 1);
  }
  return sets;
};

const renameSetByIndex = (_sets: WallSet[], setIndex: number, name: string) => {
  const sets = _.cloneDeep(_sets);
  sets[setIndex].name = name;
  return sets;
};

const addCameraEventToSet = (positionIndex: number, _sets: WallSet[], currentSetIndex: number, cameras: EdgeCamera.CameraItem[], withAlerts: boolean) => {
  const sets = _.cloneDeep(_sets);
  if (!withAlerts) {
    let cameraIndexCounter = 0;
    /**
     * Firstly fill selected position, then other empty from start
     */
    sets[currentSetIndex].tiles[positionIndex].data = {
      edgeId: cameras[cameraIndexCounter].edgeId,
      locationId: cameras[cameraIndexCounter].locationId,
      cameraId: cameras[cameraIndexCounter].edgeOnly.cameraId,
    };

    const layout = sets[currentSetIndex].layout;
    const camerasCount = wallLayoutCameraCountV2[layout];
    for(let i = 0; i < camerasCount; i++) {
      if (!sets[currentSetIndex].tiles[i].data) {
        cameraIndexCounter++;
        if (cameras[cameraIndexCounter]) {
          sets[currentSetIndex].tiles[i].data = {
            edgeId: cameras[cameraIndexCounter].edgeId,
            locationId: cameras[cameraIndexCounter].locationId,
            cameraId: cameras[cameraIndexCounter].edgeOnly.cameraId,
          };
        }
      }
    }
  } else {
    let currentEvents = [];
    cameras.forEach(camera => {
      currentEvents = currentEvents.concat(camera.alertEvents);
    });
    sets[currentSetIndex].tiles[positionIndex].data = {
      events: currentEvents,
    };
  }

  return sets;
};

const removeSelectedEvent = (positionIndex: number, _sets: WallSet[], currentSetIndex: number, eventId: string, cameraId: string) => {
  const sets = _.cloneDeep(_sets);
  const events = sets[currentSetIndex].tiles[positionIndex].data.events;
  const eventIndex = events.findIndex(event => event.eventId === eventId && event.cameraId === cameraId);
  events.splice(eventIndex, 1);
  if (!events.length) {
    sets[currentSetIndex].tiles[positionIndex].data = null;
  } else {
    sets[currentSetIndex].tiles[positionIndex].data.events = events;
  }
  return sets;
};

const updateSetPosition = (positionIndex: number, _sets: WallSet[], currentSetIndex: number, selectionResult: CameraAlertSelectorResult): WallSet[] => {
  const sets = _.cloneDeep(_sets);
  if (selectionResult?.selectedCameras) {
    const cameras = selectionResult.selectedCameras;
    let cameraIndexCounter = 0;
    const selectedTileCamera = cameras[cameraIndexCounter];
    if (!selectedTileCamera) {
      sets[currentSetIndex].tiles[positionIndex] = {
        cameraTileConfig: selectionResult.cameraTileConfig,
        alertTileConfig: selectionResult.alertTileConfig,
        data: null,
      };
    } else {
      // const camera = {
      //   edgeId: selectedTileCamera.edgeId,
      //   locationId: selectedTileCamera.locationId,
      //   cameraId: selectedTileCamera.cameraId,
      // };
      //
      // sets[currentSetIndex].tiles[positionIndex] = {
      //   cameraTileConfig: selectionResult.cameraTileConfig,
      //   alertTileConfig: selectionResult.alertTileConfig,
      //   data: camera,
      // };

      const layout = sets[currentSetIndex].layout;
      const camerasCount = wallLayoutCameraCountV2[layout];
      for(let i = positionIndex; i < camerasCount; i++) {
        if (!sets[currentSetIndex].tiles[i]?.data) {
          if (cameras[cameraIndexCounter]) {
            sets[currentSetIndex].tiles[i] = {
              ...sets[currentSetIndex].tiles[i],
              data: {
                edgeId: cameras[cameraIndexCounter].edgeId,
                locationId: cameras[cameraIndexCounter].locationId,
                cameraId: cameras[cameraIndexCounter].cameraId,
              },
            };
          }
          cameraIndexCounter++;
        }

      }
    }
  }

  if (selectionResult?.selectedEvents) {
    const selectedEvents = selectionResult?.selectedEvents;
    let currentEvents = [];
    selectedEvents.forEach(alertEvents => {
      currentEvents = currentEvents.concat(alertEvents);
    });
    sets[currentSetIndex].tiles[positionIndex] = {
      cameraTileConfig: selectionResult.cameraTileConfig,
      alertTileConfig: selectionResult.alertTileConfig,
      data: { events: currentEvents },
    };
  }

  if (selectionResult?.selectedEventCameras) {
    const selectedEventCameras = selectionResult?.selectedEventCameras;
    let cameraEvents = [];
    selectedEventCameras.forEach(alertEvents => {
      cameraEvents = cameraEvents.concat(alertEvents);
    });
    sets[currentSetIndex].tiles[positionIndex] = {
      cameraTileConfig: selectionResult.cameraTileConfig,
      alertTileConfig: selectionResult.alertTileConfig,
      data: { camerasEvents: cameraEvents },
    };
  }

  return sets;
};

const removeSelectedCameraEvent = (positionIndex: number, _sets: WallSet[], currentSetIndex: number, cameraId: string) => {
  const sets = _.cloneDeep(_sets);
  const cameraEvents = sets[currentSetIndex].tiles[positionIndex].data.camerasEvents;
  const cameraIndex = cameraEvents.findIndex(cameraEvent => cameraEvent.cameraId === cameraId);
  cameraEvents.splice(cameraIndex, 1);
  if (!cameraEvents.length) {
    sets[currentSetIndex].tiles[positionIndex].data = null;
  } else {
    sets[currentSetIndex].tiles[positionIndex].data.camerasEvents = cameraEvents;
  }
  return sets;
};

const resetSets = (_sets: WallSet[]) => {
  const sets = _.cloneDeep(_sets);
  return sets.map(set => {
    const layout = wallLayoutCameraCountV2[set.layout];
    return {
      ...set,
      tiles: new Array(layout).fill({
        cameraTileConfig: defaultCameraTileConfig,
        alertTileConfig: defaultAlertTileConfig,
        data: null,
      }),
    };
  });
};

const removeFilter = (filters: WallFilters, value: any, field: string): any => {
  switch (field) {
    case 'isPrivate':
      let resultIsPrivate = [...filters[field]] as boolean[];
      const indexIsPrivateFilters = resultIsPrivate.findIndex(item => item === value);
      if (indexIsPrivateFilters !== -1) {
        resultIsPrivate.splice(indexIsPrivateFilters, 1);
      }
      return resultIsPrivate;
    case 'selectedCameras':
      let resultCameras = { ...filters[field] } as Dictionary<SelectedCamera>;
      delete resultCameras[value];
      return resultCameras;
  }
  return filters[field];
};


const updateAlertsExternal = (selectionResult: CameraAlertSelectorResult): WallTile => {
  if (selectionResult?.selectedEvents) {
    const selectedEvents = selectionResult?.selectedEvents;
    let currentEvents = [];
    selectedEvents.forEach(alertEvents => {
      currentEvents = currentEvents.concat(alertEvents);
    });
    return {
      cameraTileConfig: selectionResult.cameraTileConfig,
      alertTileConfig: selectionResult.alertTileConfig,
      data: { events: currentEvents },
    };
  }

  if (selectionResult?.selectedEventCameras) {
    const selectedEventCameras = selectionResult?.selectedEventCameras;
    let cameraEvents = [];
    selectedEventCameras.forEach(alertEvents => {
      cameraEvents = cameraEvents.concat(alertEvents);
    });
    return {
      cameraTileConfig: selectionResult.cameraTileConfig,
      alertTileConfig: selectionResult.alertTileConfig,
      data: { camerasEvents: cameraEvents },
    };
  }
  return null;
};

const updateWallsByAppleTv = (walls: WallModel[], configureWallResponse: SocketModels.ConfigureAppleTv.ConfigureAppleTvResponseData): WallModel[] => {
  return walls.map(wall => {
    let appleTvId = wall.appleTvId;
    if (wall._id === configureWallResponse.oldWallId) {
      appleTvId = null;
    }
    if (wall._id === configureWallResponse.wallId) {
      appleTvId = configureWallResponse.edgeId;
    }
    return {
      ...wall,
      appleTvId: appleTvId,
    };
  });
};

const updateSelectedWallByAppleTv = (wall: WallModel, configureWallResponse: SocketModels.ConfigureAppleTv.ConfigureAppleTvResponseData): WallModel => {
  if (!wall) {
    return initialState.selectedWall;
  }
  if (wall._id === configureWallResponse.wallId) {
    let appleTvId = configureWallResponse.edgeId;
    if (wall._id === configureWallResponse.oldWallId) {
      appleTvId = null;
    }
    return {
      ...wall,
      appleTvId,
    };
  }
  return wall;
};
