import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon('search', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search.svg'));
    this.matIconRegistry.addSvgIcon('table', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboard/table.svg'));
    this.matIconRegistry.addSvgIcon('object-fall', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-fall.svg'));
    this.matIconRegistry.addSvgIcon('not-recording', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/not-recording.svg'));
    this.matIconRegistry.addSvgIcon('local-wall', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/local-wall.svg'));
    this.matIconRegistry.addSvgIcon('remote-wall', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/remote-wall.svg'));
    this.matIconRegistry.addSvgIcon('tag', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/tag.svg'));
    this.matIconRegistry.addSvgIcon('user-groups', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user-groups.svg'));
    this.matIconRegistry.addSvgIcon('door', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/door.svg'));
    this.matIconRegistry.addSvgIcon('playback-play', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/playback-play.svg'));
    this.matIconRegistry.addSvgIcon('playback-pause', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/playback-pause.svg'));
    this.matIconRegistry.addSvgIcon('home', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/home.svg'));
    this.matIconRegistry.addSvgIcon('event', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/event.svg'));
    this.matIconRegistry.addSvgIcon('custom-event', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/custom-event.svg'));
    this.matIconRegistry.addSvgIcon('shared', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/shared.svg'));
    this.matIconRegistry.addSvgIcon('share', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/share.svg'));
    this.matIconRegistry.addSvgIcon('arrow-down', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-down.svg'));
    this.matIconRegistry.addSvgIcon('plus', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/plus.svg'));
    this.matIconRegistry.addSvgIcon('stream', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/stream.svg'));
    this.matIconRegistry.addSvgIcon('walls', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/walls.svg'));
    this.matIconRegistry.addSvgIcon('video-film', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video-film.svg'));
    this.matIconRegistry.addSvgIcon('locations', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/locations.svg'));
    this.matIconRegistry.addSvgIcon('starred', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/starred.svg'));
    this.matIconRegistry.addSvgIcon('cases', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cases.svg'));
    this.matIconRegistry.addSvgIcon('shares', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/shares.svg'));
    this.matIconRegistry.addSvgIcon('layout', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/layout.svg'));
    this.matIconRegistry.addSvgIcon('stopwatch', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/stopwatch.svg'));
    this.matIconRegistry.addSvgIcon('box', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/box.svg'));
    this.matIconRegistry.addSvgIcon('alerts', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/alerts.svg'));
    this.matIconRegistry.addSvgIcon('analytics', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/analytics.svg'));
    this.matIconRegistry.addSvgIcon('more', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/more.svg'));
    this.matIconRegistry.addSvgIcon('preferences', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/preferences.svg'));
    this.matIconRegistry.addSvgIcon('time', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/time.svg'));
    this.matIconRegistry.addSvgIcon('upload', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/upload.svg'));
    this.matIconRegistry.addSvgIcon('search_list', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search_list.svg'));
    this.matIconRegistry.addSvgIcon('camera-off', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/camera-off.svg'));
    this.matIconRegistry.addSvgIcon('dashboards', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboards.svg'));
    this.matIconRegistry.addSvgIcon('status-online', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/status-online.svg'));
    this.matIconRegistry.addSvgIcon('status-offline', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/status-offline.svg'));
    this.matIconRegistry.addSvgIcon('status-unhealthy', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/status-unhealthy.svg'));
    this.matIconRegistry.addSvgIcon('cameras-cat', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cameras-cat.svg'));
    this.matIconRegistry.addSvgIcon('cable-connected', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cable-connected.svg'));
    this.matIconRegistry.addSvgIcon('cable-disconnected', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cable-disconnected.svg'));
    this.matIconRegistry.addSvgIcon('checkmark-outline', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/status-online.svg'));
    this.matIconRegistry.addSvgIcon('multiple-cameras', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/multiple-cameras.svg'));

    this.matIconRegistry.addSvgIcon('track', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/track.svg'));
    this.matIconRegistry.addSvgIcon('expand', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/expand.svg'));
    this.matIconRegistry.addSvgIcon('collapse', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/collapse.svg'));
    this.matIconRegistry.addSvgIcon('help', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/help.svg'));
    this.matIconRegistry.addSvgIcon('organization', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/organization.svg'));
    this.matIconRegistry.addSvgIcon(
      'arrow_drop_up_big',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow_drop_up_big.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'arrow_drop_down_big',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow_drop_down_big.svg'),
    );
    this.matIconRegistry.addSvgIcon('back_arrow', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/back_arrow.svg'));
    this.matIconRegistry.addSvgIcon('bank', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/bank.svg'));
    this.matIconRegistry.addSvgIcon('videocam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/videocam.svg'));
    this.matIconRegistry.addSvgIcon('drag', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/drag.svg'));
    this.matIconRegistry.addSvgIcon('linkedin', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/linkedin.svg'));

    // Layouts
    this.matIconRegistry.addSvgIcon('9-cameras', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/layouts/9-cameras.svg'));
    this.matIconRegistry.addSvgIcon('6-cameras', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/layouts/6-cameras.svg'));
    this.matIconRegistry.addSvgIcon('4-cameras', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/layouts/4-cameras.svg'));
    this.matIconRegistry.addSvgIcon('3-cameras', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/layouts/3-cameras.svg'));
    this.matIconRegistry.addSvgIcon('2-cameras', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/layouts/2-cameras.svg'));
    this.matIconRegistry.addSvgIcon('1-camera', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/layouts/1-camera.svg'));

    // Thumbnail Layouts
    this.matIconRegistry.addSvgIcon('6-cam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/6-cam.svg'));
    this.matIconRegistry.addSvgIcon('9-cam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/9-cam.svg'));
    this.matIconRegistry.addSvgIcon('4-cam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/4-cam.svg'));
    this.matIconRegistry.addSvgIcon('3-cam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/3-cam.svg'));
    this.matIconRegistry.addSvgIcon('2-cam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/2-cam.svg'));
    this.matIconRegistry.addSvgIcon('1-cam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/1-cam.svg'));
    this.matIconRegistry.addSvgIcon('6-cam_v', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/6-cam_v.svg'));
    this.matIconRegistry.addSvgIcon('8-cam_v', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/8-cam_v.svg'));
    this.matIconRegistry.addSvgIcon('4-cam_1_3', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/4-cam_1_3.svg'));
    this.matIconRegistry.addSvgIcon('5-cam_1_4', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/5-cam_1_4.svg'));
    this.matIconRegistry.addSvgIcon('6-cam_1_5', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/6-cam_1_5.svg'));
    this.matIconRegistry.addSvgIcon('6-cam_2_4', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/6-cam_2_4.svg'));
    this.matIconRegistry.addSvgIcon('7-cam_3_4', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/7-cam_3_4.svg'));
    this.matIconRegistry.addSvgIcon('7-cam_1_6', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/7-cam_1_6.svg'));
    this.matIconRegistry.addSvgIcon('8-cam_h', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/8-cam_h.svg'));
    this.matIconRegistry.addSvgIcon('9-cam_1_8', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/9-cam_1_8.svg'));
    this.matIconRegistry.addSvgIcon('10-cam_2_8', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/10-cam_2_8.svg'));
    this.matIconRegistry.addSvgIcon('10-cam_2_8_v', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/10-cam_2_8_v.svg'));
    this.matIconRegistry.addSvgIcon('12-cam_1_11', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/12-cam_1_11.svg'));
    this.matIconRegistry.addSvgIcon('12-cam_12', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/12-cam_12.svg'));
    this.matIconRegistry.addSvgIcon('13-cam_1_center', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/13-cam_1_center.svg'));
    this.matIconRegistry.addSvgIcon('13-cam_1_12', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/13-cam_1_12.svg'));
    this.matIconRegistry.addSvgIcon('16-cam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/16-cam.svg'));
    this.matIconRegistry.addSvgIcon('16-cam_1_15', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/16-cam_1_15.svg'));
    this.matIconRegistry.addSvgIcon('20-cam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/20-cam.svg'));
    this.matIconRegistry.addSvgIcon('20-cam_2_18', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/20-cam_2_18.svg'));
    this.matIconRegistry.addSvgIcon('25-cam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/25-cam.svg'));
    this.matIconRegistry.addSvgIcon('30-cam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/30-cam.svg'));
    this.matIconRegistry.addSvgIcon('36-cam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/36-cam.svg'));
    this.matIconRegistry.addSvgIcon('48-cam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/thumbnail-layouts/48-cam.svg'));

    // Player
    this.matIconRegistry.addSvgIcon('play', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/play.svg'));
    this.matIconRegistry.addSvgIcon('calendar', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/calendar.svg'));
    this.matIconRegistry.addSvgIcon('play-alert', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/play-alert.svg'));
    this.matIconRegistry.addSvgIcon('checkmark', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/checkmark.svg'));
    this.matIconRegistry.addSvgIcon('backward', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/backward.svg'));
    this.matIconRegistry.addSvgIcon('forward', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/forward.svg'));
    this.matIconRegistry.addSvgIcon('forward-link', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/forward-link.svg'));
    this.matIconRegistry.addSvgIcon('picture', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/picture.svg'));
    this.matIconRegistry.addSvgIcon('maximize', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/maximize.svg'));
    this.matIconRegistry.addSvgIcon('addFile', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/addFile.svg'));
    this.matIconRegistry.addSvgIcon('lightning', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/lightning.svg'));
    this.matIconRegistry.addSvgIcon('images', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/images.svg'));
    this.matIconRegistry.addSvgIcon('videos', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/videos.svg'));
    this.matIconRegistry.addSvgIcon(
      'player-settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/player-settings.svg'),
    );
    this.matIconRegistry.addSvgIcon('x-circle', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/x-circle.svg'));
    this.matIconRegistry.addSvgIcon('check-circle', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/check-circle.svg'));
    this.matIconRegistry.addSvgIcon('exclamation', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/exclamation.svg'));
    this.matIconRegistry.addSvgIcon('info', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/info.svg'));
    this.matIconRegistry.addSvgIcon('delete', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete.svg'));
    this.matIconRegistry.addSvgIcon('video-cam', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video-cam.svg'));
    this.matIconRegistry.addSvgIcon('more-menu', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/more-menu.svg'));
    this.matIconRegistry.addSvgIcon('drag-handle', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/drag-handle.svg'));
    this.matIconRegistry.addSvgIcon(
      'breadcrumb-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/breadcrumb-arrow.svg'),
    );
    this.matIconRegistry.addSvgIcon('location', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/location.svg'));
    this.matIconRegistry.addSvgIcon('history', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/history.svg'));
    this.matIconRegistry.addSvgIcon('alerts', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/alerts.svg'));
    this.matIconRegistry.addSvgIcon('warning', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/warning.svg'));
    this.matIconRegistry.addSvgIcon('archive', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/archive.svg'));
    this.matIconRegistry.addSvgIcon('stats', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/stats.svg'));
    this.matIconRegistry.addSvgIcon('bar_chart', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/bar_chart.svg'));
    this.matIconRegistry.addSvgIcon('show', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/show.svg'));
    this.matIconRegistry.addSvgIcon('save', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/save.svg'));
    this.matIconRegistry.addSvgIcon('settings-light', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/settings-light.svg'));
    this.matIconRegistry.addSvgIcon('ptz-home', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/ptz-home.svg'));
    this.matIconRegistry.addSvgIcon('ptz-arrow', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/ptz-arrow.svg'));
    this.matIconRegistry.addSvgIcon('ptz-zoom-in', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/ptz-zoom-in.svg'));
    this.matIconRegistry.addSvgIcon('ptz-zoom-out', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/ptz-zoom-out.svg'));
    this.matIconRegistry.addSvgIcon('settings', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/settings.svg'));
    this.matIconRegistry.addSvgIcon(
      'side-menu-arrow-left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-menu-arrow-left.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'side-menu-arrow-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-menu-arrow-right.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'section_arrow_up',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/section_arrow_up.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'section_arrow_down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/section_arrow_down.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'section_arrow_right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/section_arrow_right.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'section_arrow_left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/section_arrow_left.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'arrow-circle-expand-outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-circle-expand-outline.svg'),
    );
    this.matIconRegistry.addSvgIcon('edit', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit.svg'));
    this.matIconRegistry.addSvgIcon('reset', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/reset.svg'));
    this.matIconRegistry.addSvgIcon(
      'workspace-icon-1',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/workspace-icon-1.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'workspace-icon-2',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/workspace-icon-2.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'workspace-icon-3',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/workspace-icon-3.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'workspace-icon-4',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/workspace-icon-4.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'workspace-icon-5',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/workspace-icon-5.svg'),
    );
    // OBJECTS
    this.matIconRegistry.addSvgIcon('tampering', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/tampering.svg'));
    this.matIconRegistry.addSvgIcon(
      'object-motion',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-motion.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-personal-safety',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-personal-safety.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-protective-gear',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-protective-gear.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-speed-control',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-speed-control.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-proximity',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-proximity.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-zone-protection',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-zone-protection.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-appearing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-appearing.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-disappearing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-disappearing.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-icon-person',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-icon-person.svg'),
    );
    this.matIconRegistry.addSvgIcon('object-pet', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-pet.svg'));
    this.matIconRegistry.addSvgIcon(
      'object-multiple',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-multiple.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-vehicle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-vehicle.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-line-crossing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-line-crossing.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-license-plate',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-license-plate.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-face-recognition',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-face-recognition.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-traffic-control',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-traffic-control.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-weapon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-weapon.svg'),
    );
    this.matIconRegistry.addSvgIcon('object-fire', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-fire.svg'));
    this.matIconRegistry.addSvgIcon(
      'object-movement',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-movement.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-occupancy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-occupancy.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-loitering',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-loitering.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-tailgating',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-tailgating.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-edge',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-edge.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-analytic',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-analytic.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-camera',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-camera.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'object-trespassing',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/object-trespassing.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'cat-safety',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/cat-safety.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'cat-customized',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/cat-customized.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'cat-identification',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/cat-identification.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'cat-tracking',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/cat-tracking.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'cat-status',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/cat-status.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'cat-custom',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/object/cat-custom.svg'),
    );
    this.matIconRegistry.addSvgIcon('eye', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/eye.svg'));
    this.matIconRegistry.addSvgIcon('clock', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/clock.svg'));
    this.matIconRegistry.addSvgIcon('eye_closed', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/eye_closed.svg'));
    this.matIconRegistry.addSvgIcon('link', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/link.svg'));
    this.matIconRegistry.addSvgIcon('clipboard', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/clipboard.svg'));
    // this.matIconRegistry.addSvgIcon('monitor-heart', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/monitor_heart.svg'));
    this.matIconRegistry.addSvgIcon('share-arrow', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/share-arrow.svg'));
    this.matIconRegistry.addSvgIcon('arrow-download', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-download.svg'));
    this.matIconRegistry.addSvgIcon('backward', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/player/backward.svg'));
    this.matIconRegistry.addSvgIcon('forward', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/player/forward.svg'));
    this.matIconRegistry.addSvgIcon('pause', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/player/pause.svg'));
    this.matIconRegistry.addSvgIcon('full-screen', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/player/full-screen.svg'));
    this.matIconRegistry.addSvgIcon('snapshot', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/player/snapshot.svg'));
    this.matIconRegistry.addSvgIcon('play', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/player/play.svg'));
    this.matIconRegistry.addSvgIcon('reply', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/comments/reply.svg'));
    this.matIconRegistry.addSvgIcon('folder', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/folder.svg'));
    this.matIconRegistry.addSvgIcon('timer', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/timer.svg'));
    this.matIconRegistry.addSvgIcon('dashboard', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboard.svg'));
    this.matIconRegistry.addSvgIcon('user', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user.svg'));
    this.matIconRegistry.addSvgIcon('lock', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/lock.svg'));
    this.matIconRegistry.addSvgIcon('edge', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edge.svg'));
    this.matIconRegistry.addSvgIcon('add-image', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add-image.svg'));
    this.matIconRegistry.addSvgIcon('action-notify', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/actions/notify.svg'));
    this.matIconRegistry.addSvgIcon('action-rest', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/actions/rest.svg'));
    this.matIconRegistry.addSvgIcon('action-gpio', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/actions/gpio.svg'));
    this.matIconRegistry.addSvgIcon('action-syslog', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/actions/syslog.svg'));
    this.matIconRegistry.addSvgIcon('action-sound', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/actions/sound.svg'));
    this.matIconRegistry.addSvgIcon('action-priority', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/actions/priority.svg'));
    this.matIconRegistry.addSvgIcon('action-reactivation', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/actions/reactivation.svg'));
    this.matIconRegistry.addSvgIcon('slack', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/actions/slack.svg'));


  }
}
