import { Injectable } from '@angular/core';
import { Room, RoomEvent, VideoPresets, createLocalTracks, RoomConnectOptions, Track, Participant, RemoteTrackPublication, RemoteVideoTrack, RemoteParticipant } from 'livekit-client';
import { HttpClient } from '@angular/common/http';
import { LiveKitModels } from '@models/livekit.model';
import { api } from '@consts/url.const';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LivekitService {
  private room: Room | undefined;

  constructor(private http: HttpClient) {
  }

  getToken(getTokenRequest: LiveKitModels.CreateLiveKitTokenRequest): Observable<LiveKitModels.CreateLiveKitTokenResponse> {
    return this.http.post<LiveKitModels.CreateLiveKitTokenResponse>(api.livekit.getToken, getTokenRequest);
  }

  stop(stopLiveKitRequest: LiveKitModels.StopLiveKitRequest): Observable<void> {
    return this.http.post<void>(api.livekit.stop, stopLiveKitRequest);
  }


  async connect(token: string, videoElement: HTMLVideoElement): Promise<Observable<void>> {
    this.room = new Room();
    const playSubject = new Subject<void>();

    // Register event handlers before connecting
    this.room.on(RoomEvent.ParticipantConnected, participant => {
      console.log(`[LiveKit] Participant connected: ${participant.identity}`);
      this.subscribeToParticipantTracks(participant as RemoteParticipant, videoElement, playSubject);
    });

    this.room.on(RoomEvent.ParticipantDisconnected, participant => {
      console.log(`[LiveKit] Participant disconnected: ${participant.identity}`);
    });

    this.room.on(RoomEvent.TrackSubscribed, (track, publication, participant) => {
      console.log(`[LiveKit] Track subscribed: ${track.kind} from ${participant.identity}`);
      if (track.kind === 'video' && videoElement) {
        this.attachRemoteTrack(track as RemoteVideoTrack, videoElement, playSubject);
      }
    });

    this.room.on(RoomEvent.TrackUnsubscribed, (track, publication, participant) => {
      console.log(`[LiveKit] Track unsubscribed: ${track.kind} from ${participant.identity}`);
    });

    this.room.on(RoomEvent.Connected, () => {
      console.log('[LiveKit] Room connected');
    });

    this.room.on(RoomEvent.Disconnected, () => {
      console.log('[LiveKit] Room disconnected');
    });

    const options: RoomConnectOptions = {};
    console.log('[LiveKit] Connecting to room...');
    await this.room.connect(api.livekit.url, token, options);
    console.log('[LiveKit] Room connection initiated');

    return playSubject.asObservable();
  }

  disconnect() {
    if (this.room) {
      console.log('[LiveKit] Disconnecting from room...');
      this.room.disconnect();
    }
  }

  private subscribeToParticipantTracks(participant: RemoteParticipant, videoElement: HTMLVideoElement, playSubject: Subject<void>) {
    participant.trackPublications.forEach(publication => {
      this.subscribeToTrack(publication, videoElement, playSubject);
    });

    participant.on(RoomEvent.TrackPublished, publication => {
      this.subscribeToTrack(publication, videoElement, playSubject);
    });

    participant.on(RoomEvent.TrackUnpublished, publication => {
      console.log(`[LiveKit] Track unpublished: ${publication.trackSid}`);
    });
  }

  private subscribeToTrack(publication: RemoteTrackPublication, videoElement: HTMLVideoElement, playSubject: Subject<void>) {
    publication.on('subscribed', track => {
      if (track.kind === 'video' && videoElement) {
        this.attachRemoteTrack(track as RemoteVideoTrack, videoElement, playSubject);
      }
    });

    publication.on('unsubscribed', track => {
      if (track.kind === 'video' && videoElement.srcObject) {
        console.log(`[LiveKit] Removing remote track`);
        videoElement.srcObject = null;
      }
    });
  }

  attachRemoteTrack(track: RemoteVideoTrack, videoElement: HTMLVideoElement, playSubject: Subject<void>) {
    console.log(`[LiveKit] Attaching remote track`);
    if (!videoElement.srcObject) {
      videoElement.srcObject = new MediaStream([track.mediaStreamTrack]);
      videoElement.play()
        .then(() => {
          console.log('[LiveKit] Video is playing');
          playSubject.next();
        })
        .catch(error => {
          console.error(`[LiveKit] Error playing video: ${error}`);
        });
    } else {
      console.log(`[LiveKit] Video element already has a srcObject`);
    }
  }
}
